import Events from './events';

export default class Lifecycle {
  constructor() {
    this.archive = {};
    Events.onAnyEvent((eventName, payload) => {
      this.archive[eventName] = this.archive[eventName] || [];
      this.archive[eventName].push(payload);
    });
  }

  listen(eventName, callback) {
    Events.on(eventName, callback);
  }

  listenAndPlayback(eventName, callback) {
    Events.on(eventName, callback);
    (this.archive[eventName] || []).forEach(payload => callback(eventName, payload));
  }

  onAnyEvent(callback) {
    Events.onAnyEvent(callback);
  }
}
