import PluginBase from './base';
import logger from '../lib/logger';
import store from '../lib/store';
import settings from '../lib/settings';

export const EXPERIMENT_STORAGE_KEY = 'concert-experiment';
const CONTROL_ID = 0;
const NO_EXPERIMENT_VALUE = 'none';

export default class ConcertExperiments extends PluginBase {
  onSettingsLoaded() {
    this.selectExperimentForUser();
  }

  selectExperimentForUser() {
    const experiments = getExperimentsFromSettings(this.app.settings);

    // Bail if the user doesn't support sessionStorage
    if (typeof window.sessionStorage === 'undefined') {
      logger.log(`Browser does not support session storage.`);
      return;
    }

    if (!experiments.length) {
      logger.log(`No experiments are active.`);
      return;
    }

    const qParams = new URLSearchParams(window.location.search);
    let specifiedExperimentSelection = sessionStorage.getItem(EXPERIMENT_STORAGE_KEY);

    if (qParams.get('concert_experiment_group')) {
      // this won't get stored or saved, it will exist for but a fleeting moment of time -
      // the disappering instant of a user's session, however, ironically, possibly even paradoxically,
      // in this manifestation: sharable and relivable. Take that postmoderninsm.
      specifiedExperimentSelection = qParams.get('concert_experiment_group');
      logger.log(`Using experiment group specified from query params ${specifiedExperimentSelection}`);
    }

    if (specifiedExperimentSelection) {
      logger.log(`Loading experiment from session storage: ${specifiedExperimentSelection}`);

      if (specifiedExperimentSelection !== NO_EXPERIMENT_VALUE) {
        this.loadExperimentForUser(specifiedExperimentSelection, experiments);
      }
      return;
    }

    const experimentSelection = selectNewExperiment(experiments);

    if (!experimentSelection) {
      logger.log('User was not chosen for an experiment');
      sessionStorage.setItem(EXPERIMENT_STORAGE_KEY, NO_EXPERIMENT_VALUE);
      return;
    }

    const [experimentId, variationId] = experimentSelection;
    const chosenExperimentAndVariation = `${experimentId}.${variationId}`;

    logger.log(`Selected experiment ${chosenExperimentAndVariation} for user`);
    sessionStorage.setItem(EXPERIMENT_STORAGE_KEY, chosenExperimentAndVariation);

    this.loadExperimentForUser(chosenExperimentAndVariation, experiments);
  }

  loadExperimentForUser(experimentAndVariation, experiments) {
    if (!validateExperimentAndVariation(experimentAndVariation, experiments)) {
      return;
    }

    const [experimentId, variationId] = experimentAndVariation.split('.').map(id => parseInt(id));

    store.set('concert-experiment-id', experimentId);
    store.set('concert-experiment-variation-id', variationId);

    const experiment = experiments.find(e => e.id === experimentId);
    const variation = experiment.variations.find(v => v.id === variationId);

    if (variationId === CONTROL_ID && (!variation || !variation.config)) {
      return;
    }

    const { config } = variation;

    logger.log(`Applying experiment variation config ${experimentAndVariation}`, config);

    this.app.addVariable('c_exp', experimentAndVariation);
    settings.add(config);
  }
}

/**
 * Convert a ConcertAds settings object to a collection of Experiment instances,
 * filtered by only active experiments.
 *
 * @param {Object} settings ConcertAds settings object
 */
export function getExperimentsFromSettings(settings) {
  if (!settings.experiments || !settings.experiments.length) return [];
  return settings.experiments
    .map(e => new Experiment(e))
    .filter(e => e.isActive())
    .filter(e => e.hasShare());
}

/**
 * Select a new experiment from a list of active experiments.
 *
 * @param {Array} experiments Experiments
 */
function selectNewExperiment(experiments) {
  const chosenExperimentId = selectBasedOnShare(experiments);

  if (!chosenExperimentId) {
    return;
  }

  const experiment = experiments.find(e => e.id === chosenExperimentId);

  if (!experiment.variations || !experiment.variations.length) {
    logger.log(`No variations are available for chosen experiment #${experiment.id}`);
    return;
  }

  const variationsWithShares = withShares(experiment.variations, true);
  const chosenVariationId = selectBasedOnShare(variationsWithShares);

  return [chosenExperimentId, chosenVariationId];
}

/**
 * From a list of experiments or variations, return a collection of simple objects
 * that list the ID and the share for each item. Since some items may not have
 * shares defined, this method calculates the remainder of shares available and
 * assigns them evenly to items as needed.
 *
 * @param {Array} items   List of experiments or variations
 * @param {*} addControl  Whether to insert a control variable with id: 0
 */
export function withShares(items, addControl = false) {
  const shouldAddControl = addControl && !items.find(i => i.id === 0);
  const remainder = items.reduce((sum, item) => (sum -= item.share ? item.share : 0), 1);
  const itemsToSplit = items.filter(item => !item.share).length + (shouldAddControl ? 1 : 0);
  const perItemSplit = itemsToSplit ? remainder / itemsToSplit : 0;

  // Compile the list of items with their original ID
  // and either the specified share or the split remainder.
  const itemsWithShares = items.map(item => {
    return {
      id: item.id,
      share: item.share || perItemSplit,
    };
  });

  // If we need to add a control variable, return it as id 0 with the split
  // (or entire remainder) of the shares.
  if (shouldAddControl) {
    return [{ id: 0, share: perItemSplit }].concat(itemsWithShares);
  }

  return itemsWithShares;
}

/**
 * Given a list of items and a number (needle) between 0 and 1, select the share
 * that matches the needle. We do this by assigning each item a cumulative share
 * value "stacked" one after the other for correct placement of the needle.
 * Returns the ID of the matching item.
 *
 * @param {Array} items      A list of items or variations (output of withShares()).
 * @param {number?} needle   A number between 0 and 1. Default: random number.
 */
export function selectBasedOnShare(items, needle) {
  needle = typeof needle === 'undefined' ? Math.random() : needle;

  let accumulatingShare = 0;
  const cumulativeItems = items.map(({ id, share }) => {
    accumulatingShare += share;

    return {
      id,
      share: accumulatingShare,
    };
  });

  const matchedItem = cumulativeItems.find(item => needle <= item.share);

  if (!matchedItem) {
    return false;
  }

  return items.find(item => matchedItem.id === item.id).id;
}

/**
 * Validate an experimentAndVariation ID against a list of experiments.
 *
 * @param {string} experimentAndVariation Experiment and variatio notation, like '1.1'
 * @param {Array} experiments List of experiments
 */
export function validateExperimentAndVariation(experimentAndVariation, experiments) {
  if (!experimentAndVariation.match(/\d+\.\d+/)) {
    logger.log(`${experimentAndVariation} is not a valid experiment format`);
    return false;
  }

  const [experimentId, variationId] = experimentAndVariation.split('.').map(id => parseInt(id));

  const experiment = experiments.find(e => e.id === experimentId);

  if (!experiment) {
    logger.log(`${experimentId} is not a valid experiment`);
    return false;
  }

  if (variationId !== 0 && !experiment.variations.find(v => v.id === variationId)) {
    logger.log(`${variationId} is not a valid experiment variation`);
    return false;
  }

  return true;
}

export class Experiment {
  /**
   * Create a new Experiment.
   *
   * @param {{
   *   id: number;
   *   start_date?: string;
   *   end_date?: string;
   *   share: number;
   *   variations: Array<{
   *     id: number,
   *     share?: number,
   *     config: any,
   *   }>;
   * }} data Configuration data for an experiment.
   */
  constructor(data) {
    this.data = data;
  }

  get id() {
    return this.data.id;
  }

  get startDate() {
    if (!this.data.start_date) return null;

    return getUTCDate(this.data.start_date);
  }

  get endDate() {
    if (!this.data.end_date) return null;

    return getUTCDate(this.data.end_date);
  }

  get share() {
    return this.data.share;
  }

  get variations() {
    return this.data.variations;
  }

  /**
   * An experiment is active when:
   * - No start or end date
   * - Start date is in the past, and no end date
   * - Start date is in the past, and end date is in the future
   * - No start date, and end date is in the future
   */
  isActive() {
    const qParams = new URLSearchParams(window.location.search);
    let testDate = new Date();
    testDate.setUTCHours(0, 0, 0, 0);

    if (qParams.get('concert_experiment_date')) {
      // ALLOW_TIME_TRAVEL = true
      testDate = getUTCDate(qParams.get('concert_experiment_date'));
      logger.log(`Using "now" date from query param date ${testDate}.`);
    }

    if (!this.startDate && !this.endDate) {
      return true;
    }

    if (!this.endDate) {
      return this.startDate <= testDate;
    }

    if (!this.startDate) {
      return this.endDate >= testDate;
    }

    return this.startDate <= testDate && this.endDate >= testDate;
  }

  hasShare() {
    return Boolean(this.share);
  }
}

/**
 * Get a Date object set to the value of a UTC input.
 *
 * @param {string} dateString YYYY-mm-dd
 */
function getUTCDate(dateString) {
  const [year, month, date] = dateString.split('-');
  const utcSeconds = Date.UTC(year, month - 1, date, 0, 0, 0, 0);

  return new Date(utcSeconds);
}
