import { fetchJson } from '../lib/fetch_json';
import Logger from '../lib/logger';
import { getEntryUrl, getOrCreateUid } from './context';
import CryptoJS from 'crypto-js';

export default {
  loadPurchaseIntent(options = {}) {
    return new Promise(resolve => {
      const { endpoint, targetingKey, apiKey, requestType } = options;

      if (!endpoint || !targetingKey || !apiKey || !requestType) {
        Logger.error(`An endpoint, targetingKey, apiKey, and requestType
         must be passed to the loadPurchaseIntent classifier`);
        return resolve(false);
      }

      // Check to see if page is homepage, if not we are not going to make the call to the purchase intent classifier
      const pageTypeDfp = this.settings.dfpVariables.page_type ? this.settings.dfpVariables.page_type : '';

      if (getPageType(pageTypeDfp) === 'Homepage' || getPageType(pageTypeDfp) === 'home_page') {
        Logger.log(
          `Not making a call to the Purchase Intent Classifier, because the page is of type ${getPageType(pageTypeDfp)}`
        );
        return resolve(false);
      }

      const entryId = this.settings.dfpVariables.entry_id
        ? this.settings.dfpVariables.entry_id[0]
        : CryptoJS.SHA3(getEntryUrl(), { outputLength: 224 }).toString();
      const entryTitle = this.settings.dfpVariables.entry_title ? this.settings.dfpVariables.entry_title[0] : '';
      const pageUrl = getEntryUrl();
      const referrer = window.location.origin ? window.location.origin : '';
      const uid = getOrCreateUid();
      const pageLoadId = window.pageloadId ? window.pageloadId : '';

      const payload = {
        pageLoadId,
        uid,
        cid: entryId,
        title: entryTitle,
        url: pageUrl,
        ref: referrer,
        request_type: requestType,
      };

      const customHeaders = {
        Authorization: `Bearer ${apiKey}`,
      };

      fetchJson(endpoint, { method: 'POST', headers: customHeaders, contentType: 'application/json', data: payload })
        .then(result => {
          const { data } = result;
          if (data && data.purchase_segment && Array.isArray(data.purchase_segment)) {
            Logger.log(`Successfully received data for Purchase Intent segments`);
            let segments = [];

            data.purchase_segment.forEach(segmentPair => {
              const [[segment, confidence]] = Object.entries(segmentPair);

              if (parseFloat(confidence) >= 0.8) {
                segments.push(segment);
              }
            });

            Logger.log(`Sending segments to targeting key ${targetingKey} with values: ${segments}`);

            resolve({
              [targetingKey]: segments,
            });
          } else {
            resolve(false);
          }
        })
        .catch(e => {
          Logger.error(`loadPurchaseIntent failed to fetch from ${endpoint}`, { error: e.message });
          resolve(false);
        });
    });
  },
};

function getPageType(pageTypeDfp) {
  if (Array.isArray(pageTypeDfp)) {
    return pageTypeDfp[0];
  } else {
    return pageTypeDfp;
  }
}
