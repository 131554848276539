import Logger from '../logger';
import Slot from '../slot';
import ScriptLoader from '../script_loader';
import Constants from '../constants';

export default class OutbrainSlot extends Slot {
  constructor(app, data) {
    super(app, data);

    this.outbrainElement = document.createElement('div');
  }

  /**
   * Show an ad in this slot
   * @return {undefined}
   */
  render() {
    if (!super.render()) return;

    Logger.log(`Rendering ${this.name}`);

    this.setUpOutbrain();
    this.loadScript();
  }

  /**
   * Determine whether this slot can be displayed on the page
   * @return {boolean}
   */
  canBeDisplayed() {
    return (
      super.canBeDisplayed() &&
      this.app.settings.outbrainUrl &&
      this.app.settings.outbrainTemplate &&
      this.data.widgetId
    );
  }

  /**
   * Add required attributes to Outbrain wrapper element
   * @return {undefined}
   */
  setUpOutbrain() {
    const attributes = {
      'data-src': Constants.PRODUCTION ? window.location.href : getTestUrl(),
      'data-widget-id': this.data.widgetId,
      'data-ob-template': this.app.settings.outbrainTemplate,
    };

    Object.keys(attributes).forEach(attribute => this.outbrainElement.setAttribute(attribute, attributes[attribute]));

    this.outbrainElement.classList.add('OUTBRAIN');

    this.element.appendChild(this.outbrainElement);
  }

  destroyWrapper() {
    this.wrapper.destroy();
  }

  /**
   * Load Outbrain script
   * @return {undefined}
   */
  loadScript() {
    const dependency = {
      url: this.app.settings.outbrainUrl,
      timeout: 2000,
      parentElement: this.outbrainElement,
    };

    new ScriptLoader()
      .load(dependency)
      .then(url => {
        Logger.log(`${this.name} script ${url} has loaded successfully`);
        this.rendered();
        this.markAsDisplayed();
      })
      .catch(error => {
        Logger.log(`Error loading ${this.name} script: ${error}`);
        Logger.log(`${this.name} has been collapsed.`);
        this.wrapper.collapseSlotWrapper();
        this.collapsed();
      });
  }

  static isSlotTypeFor(config) {
    return config.type === 'outbrain';
  }
}

/**
 * Create Outbrain URL for testing locally and on staging
 * @return {String}
 */
function getTestUrl() {
  const pathname = window.location.pathname.length > 1 ? window.location.pathname : '';
  const domain = location.href.indexOf('--net') != -1 ? 'net' : 'com';
  let host = window.location.host;
  let testHost = host.slice(0, host.indexOf(`--${domain}`)) + `.${domain}`;
  testHost = testHost.replace('--', '-');

  return `https://${testHost}${pathname}`;
}
