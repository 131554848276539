/* eslint-disable camelcase */

import Logger from '../logger';
import Slot from '../slot';
import ScriptLoader from '../script_loader';

export default class MetabetSlot extends Slot {
  constructor(app, data) {
    super(app, data);
  }

  /**
   * Show an ad in this slot
   * @return {undefined}
   */
  render() {
    if (!super.render()) return;

    Logger.log(`Loading ${this.name}`);

    this.setUpMetabet();
    this.loadMetabetScript();
  }

  /**
   * Determine whether this slot can be displayed on the page
   * @return {boolean}
   */
  canBeDisplayed() {
    return super.canBeDisplayed() && this.app.settings.metabetUrl;
  }

  /**
   * Set up Metabet element
   * @return {undefined}
   */
  setUpMetabet() {
    const metabetClasses = ['metabet-adtile-auto', 'metabet-size-300x167'];

    metabetClasses.forEach(metabetClass => {
      this.element.classList.add(metabetClass);
    });
  }

  /**
   * Load Metabet script
   * @return {undefined}
   */
  loadMetabetScript() {
    const dependency = {
      url: this.app.settings.metabetUrl,
      timeout: 2000,
      parentElement: this.wrapper.wrapperElement,
      defer: true,
    };

    new ScriptLoader()
      .load(dependency)
      .then(url => {
        Logger.log(`${this.name} script ${url} has loaded successfully`);
        this.rendered();
        this.markAsDisplayed();
      })
      .catch(error => {
        Logger.log(`Error loading ${this.name} script: ${error}`);
        Logger.log(`${this.data.name} has been collapsed.`);
        this.wrapper.collapseSlotWrapper();
        this.collapsed();
      });
  }

  static isSlotTypeFor(config) {
    return config.type === 'metabet';
  }
}
