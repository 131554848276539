class Store {
  constructor() {
    this.reset();
  }

  reset() {
    this.store = {};
  }

  set(key, value) {
    this.store[key] = value;
  }

  append(key, value) {
    let previousValue = this.get(key) || [];

    if (!Array.isArray(previousValue)) {
      previousValue = [previousValue];
    }

    const copy = [...previousValue];

    copy.push(value);
    this.set(key, copy);
  }

  get(key) {
    return this.store[key];
  }
}

export default new Store();
