/**
 * Prebid Helper Class for pbjs specific methods
 *
 */
export default class PrebidHelper {
  constructor() {
    this.setupPrebid();
  }

  /**
   * Set up Prebid.js for header bidding
   *
   */
  setupPrebid() {
    pbjs.que.push(function() {
      pbjs.setConfig({
        // Ensure bids are randomized
        bidderSequence: 'random',
      });

      pbjs.aliasBidder('rubicon', 'rubiconLite'); // see https://app.asana.com/0/1118460880734320/1147303436006619
      pbjs.aliasBidder('rubicon', 'rubi__mm');
      pbjs.aliasBidder('rubicon', 'rubi__ttd'); // https://app.asana.com/0/859995650493254/1147303436006619/f
    });
  }

  /**
   * Set up configs for Prebid.js
   *
   */
  configure(app) {
    let userSync = {
      // Turn on iframe based user sync pixels (see https://github.com/voxmedia/revenue-platforms/issues/273#issuecomment-351798327)
      iframeEnabled: true,
    };

    if (app.settings.prebid.defaultConfig && app.settings.prebid.defaultConfig.usersync) {
      userSync = Object.assign(userSync, app.settings.prebid.defaultConfig.usersync);
    }

    pbjs.que.push(function() {
      pbjs.setConfig({
        userSync,
        // Define price bucket granularity
        priceGranularity: app.settings.prebid.priceGranularity || 'medium',
        // Send all bids or just winning bids
        enableSendAllBids: app.settings.prebid.enableSendAllBids === false ? false : true,
        // Options to influence targeting keys
        targetingControls: app.settings.prebid.targetingControls ? app.settings.prebid.targetingControls : {},
      });

      pbjs.enableAnalytics(
        app.settings.prebid.analyticsAdapters || [
          {
            provider: 'concert',
          },
        ]
      );
    });
  }
}

/**
 * Register to listen to Prebid events
 *
 * @param {ConcertAds} app
 */
function listenForEvents(app) {
  pbjs.que.push(function() {
    app.prebidEvents.initialize();
  });
}

/**
 * Get the current Prebid user sync value
 *
 */
function getUserSync() {
  return pbjs.getConfig().userSync;
}

/**
 * Refresh prebid user Ids -- documentation: https://docs.prebid.org/dev-docs/publisher-api-reference/refreshUserIds.html
 */
function refreshUserIds() {
  return pbjs.refreshUserIds();
}

/**
 * Update the current Prebid user sync value
 *
 */
function updateUserSync(userSync) {
  pbjs.que.push(function() {
    pbjs.setConfig({ userSync });
  });
}

/**
 * Determine if the Prebid.js library is loaded
 *
 */
function prebidScriptLoaded() {
  return window.pbjs && window.pbjs.libLoaded;
}

export { prebidScriptLoaded, listenForEvents, getUserSync, updateUserSync, PrebidHelper, refreshUserIds };
