import SlotWrapper from '../../lib/slot_wrapper';
import Constants from '../../lib/constants';
import { SLOT_TYPES } from './install';
import GamAdPreviewSlot from '../../lib/slots/concert_preview_slot';

export function registerMixin(ConcertAds) {
  ConcertAds.prototype.registerSlot = function(config) {
    const SlotClass = Constants.CONCERT_PREVIEW ? GamAdPreviewSlot : SLOT_TYPES.find(s => s.isSlotTypeFor(config));

    let slot = new SlotClass(this, config);
    slot.wrapper = SlotWrapper.newFromExistingSlot(slot);

    this.slots.push(slot);

    slot.inserted();

    return slot;
  };
}
