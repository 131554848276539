/* global permutive */
/* eslint-disable camelcase */

import Logger from '../lib/logger';
import PluginBase from './base';
import Events, { EventTypes } from '../lib/events';
import ScriptLoader from '../lib/script_loader';
import { checkOneTrustCookieConsent } from '../lib/utils';

export default class Permutive extends PluginBase {
  constructor({ app }) {
    super({ app });
    this.initialConsentCheckComplete = false;
    this.handleConsentUpdate = this.handleConsentUpdate.bind(this);
  }

  onSettingsLoaded() {
    if (this.isPermutiveSetupEligible() && this.requiresPermutiveSetup()) {
      this.listenForConsentUpdates();

      if (window.OneTrust && window.OnetrustActiveGroups) {
        const initialConsent = checkOneTrustCookieConsent();
        Logger.log(`Initial OneTrust consent status: ${initialConsent}`);
        this.handleConsentUpdate();
      }
    }
  }

  handleConsentUpdate() {
    const consentGranted = checkOneTrustCookieConsent();
    Logger.log(`Handling consent update: ${consentGranted}`);

    if (consentGranted) {
      this.initializePermutive();
    } else {
      this.clearPermutiveData();
    }

    this.initialConsentCheckComplete = true;
  }

  async initializePermutive() {
    try {
      Logger.log('Initializing Permutive after consent granted');

      const permutiveWasCleared = !window.localStorage.getItem('permutive-id');

      if (!window.permutive) {
        addPermutiveProvidedCode(this.app.settings.permutive.projectId, this.app.settings.permutive.publicApiKey);
      }

      permutive.consent({ opt_in: true, token: 'CONSENT_GRANTED' });

      if (!this.initialConsentCheckComplete || permutiveWasCleared) {
        if (!this.onlySyncClassifiers()) {
          await this.loadClassifierDependencies();
        }
        await this.setupPermutiveTargeting();
      }
    } catch (error) {
      Logger.error(`Failed to initialize Permutive: ${error}`);
    }
  }

  /**
   * Do required configs exist to set up Permutive?
   * @return {Boolean}
   */
  isPermutiveSetupEligible() {
    return this.app.settings.permutive?.projectId && this.app.settings.permutive?.publicApiKey;
  }

  /**
   * Does this site need Permutive to be set up? Vox O&O and SaaS sites that have Permutive enabled need the
   * Permutive script and code provide by Permutive added. Tracking data including classifiers is also sent with page data.
   * @return {Boolean}
   */
  requiresPermutiveSetup() {
    return this.app.settings.permutiveEnabled && !this.onlySyncClassifiers();
  }

  /**
   * Does this site only need classifiers sent to Permutive? NYM sets up Permutive so only needs ConcertAds to
   * send classifier data as custom events. This is temporary, NYM will be updated so classifier data is sent
   * along with other page data.
   * @return {Boolean}
   */
  onlySyncClassifiers() {
    return !!this.app.settings.permutiveClassifiersEnabled;
  }

  /**
   * Is the Navi Widget enabled on this network?
   * @return {Boolean}
   */
  syncingWithNaviWidgetEnabled() {
    return !!this.app.settings.permutiveSyncingWithNaviWidgetEnabled;
  }

  /**
   * Listen for OneTrust consent updates
   */
  listenForConsentUpdates() {
    window.removeEventListener('OneTrustGroupsUpdated', this.handleConsentUpdate);
    window.addEventListener('OneTrustGroupsUpdated', this.handleConsentUpdate);
  }

  /**
   * Clear all Permutive data and targeting when consent is withdrawn
   * @returns {undefined}
   */
  clearPermutiveData() {
    try {
      Logger.log('Clearing Permutive data and targeting');

      // Set consent to false if Permutive exists
      if (window.permutive) {
        permutive.consent({ opt_in: false });
      }

      // Clear Permutive segments from localStorage
      try {
        window.localStorage.removeItem('_pdfps');
        window.localStorage.removeItem('permutive-id');

        // Clear any other Permutive-specific localStorage items
        Object.keys(window.localStorage).forEach(key => {
          if (key.startsWith('_perm_') || key.startsWith('permutive')) {
            window.localStorage.removeItem(key);
          }
        });
      } catch (storageError) {
        Logger.error(`Error clearing Permutive localStorage: ${storageError}`);
      }

      // Clear Permutive cookies
      try {
        const cookies = document.cookie.split(';');
        cookies.forEach(cookie => {
          const cookieName = cookie.split('=')[0].trim();
          if (cookieName.toLowerCase().includes('permutive')) {
            document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=.${
              window.location.hostname
            }`;
          }
        });
      } catch (cookieError) {
        Logger.error(`Error clearing Permutive cookies: ${cookieError}`);
      }

      // Clear targeting variables
      try {
        this.app.addVariable('permutive', []);
      } catch (variableError) {
        Logger.error(`Error clearing Permutive targeting variables: ${variableError}`);
      }

      // Remove any existing Permutive events listeners
      try {
        Events.off(EventTypes.allClassifierValuesAdded);
      } catch (eventError) {
        Logger.error(`Error removing Permutive event listeners: ${eventError}`);
      }

      Logger.log('Permutive data, cookies, and targeting cleared');
    } catch (error) {
      Logger.error(`Failed to clear Permutive data: ${error}`);
    }
  }

  /**
   * Set up Permutive targeting after consent is granted
   * @returns {Promise<void>}
   */
  async setupPermutiveTargeting() {
    Logger.log('Setting up Permutive targeting after consent granted');
    this.addPermutiveTargeting();

    if (this.onlySyncClassifiers()) {
      Events.on(EventTypes.allClassifierValuesAdded, () => {
        this.syncClassifiers();
        this.addPermutiveTargeting();
      });
    } else {
      Events.on(EventTypes.allClassifierValuesAdded, () => {
        this.loadClassifierDependencies();
      });
    }

    if (this.syncingWithNaviWidgetEnabled()) {
      this.syncNaviWidgetValues();
    }
  }

  /**
   * Setup Permutive code and tracking
   * @return {undefined}
   */
  async loadClassifierDependencies() {
    this.addPageDataToPermutive();
    await this.setPermutiveIdentity();
    await this.loadPermutiveScript();
  }

  /**
   * Send page data to Permutive
   * @return {undefined}
   */
  addPageDataToPermutive() {
    permutive.addon('web', getPageData(this.app.variables));
  }

  /**
   * Will use the permutive publisher provided user id
   * instead of trying to fingerprint this user
   * @returns {undefined}
   */
  async setPermutiveIdentity() {
    const permutiveIdentity = await this.app.identityFor('permutive');
    if (permutiveIdentity) {
      permutive.identify([
        {
          id: permutiveIdentity,
          tag: 'publisherUserId',
        },
      ]);
    } else {
      Logger.log(`Unable to set publisher id for permutive`);
    }
  }

  /**
   * Set Permutive targeting
   * @return {undefined}
   */
  addPermutiveTargeting() {
    const pdfps = window.localStorage.getItem('_pdfps');
    this.app.addVariable('permutive', pdfps ? JSON.parse(pdfps) : []);
  }

  /**
   * Load Permutive script
   * @return {undefined}
   */
  async loadPermutiveScript() {
    const dependency = {
      url: `https://cdn.permutive.com/${this.app.settings.permutive.projectId}-web.js`,
      timeout: this.app.settings.permutive?.scriptTimeout || 3000,
    };

    try {
      const url = await new ScriptLoader().load(dependency);
      Logger.log(`Permutive script ${url} has loaded successfully`);
    } catch (error) {
      Logger.error(`Error loading Permutive script: ${error}`);
    }
  }

  /**
   * Sync classifiers with Permutive
   * @return {undefined}
   */
  syncClassifiers() {
    const { cts_keyword, cts_keyword_list, cts_iab_category, c_sg } = this.app.variables;

    if (cts_keyword) this.syncMotifTargetingWithPermutive('CtsKeyword', cts_keyword);
    if (cts_keyword_list) this.syncMotifTargetingWithPermutive('CtsKeywordList', cts_keyword_list);
    if (cts_iab_category) this.syncMotifTargetingWithPermutive('CtsIabCategory', cts_iab_category);
    if (c_sg) this.syncContextualSegmentsWithPermutive(c_sg);
  }

  /**
   * Sync Motif Targeting with Permutive
   * @param {String} key
   * @param {String} value
   * @return {Object}
   */
  syncMotifTargetingWithPermutive(key, value) {
    Logger.log(`Syncing Motif targeting to Permutive ${value}`);
    try {
      permutive.track(key, {
        value: value,
      });
    } catch (e) {
      Logger.log(`Unable to track permutive event, received ${e.name} - ${e.message}`);
    }
  }

  /**
   * Sync Contextual Segmants with Permutive
   * @param {Array} contextualSegments
   * @return {Object}
   */
  syncContextualSegmentsWithPermutive(contextualSegments) {
    contextualSegments.forEach(segment => {
      Logger.log(`Syncing contextual segments to Permutive ${segment}`);
      try {
        permutive.track('ContextClassified', {
          id: segment,
          type: 'Interest',
        });
      } catch (e) {
        Logger.log(`Unable to track permutive event, received ${e.name} - ${e.message}`);
      }
    });
  }

  /**
   * Listen for a post message from a Navi Widget creative
   * If a message is sent with the event data, track it with Permutive
   */
  syncNaviWidgetValues() {
    window.addEventListener(
      'message',
      event => {
        if (event?.data?.indexOf && event.data.indexOf('NAVI WIDGET') > 0) {
          try {
            var naviData = JSON.parse(event.data);

            if (Array.isArray(naviData)) {
              permutive.track('AdInteraction', {
                tradeInPhone: naviData[1],
                selectedPhone: naviData[2],
                carrier: naviData[3],
              });
            }
          } catch (e) {
            Logger.log(`Error tracking Permutive event for Navi Widget data, received ${e.name} - ${e.message}`);
          }
        }
      },
      false
    );
  }
}

/**
 * Get page data from variables including classifier data to send to Permutive
 * @param {Object} variables
 * @return {Object}
 */
export function getPageData(variables) {
  const {
    page_type = [''],
    entry_id = [''],
    entry_title = [''],
    entry_blurb = [''],
    entry_author = [''],
    entry_group = [''],
    entry_published_date = [new Date().toISOString()],
    keywords = '',
    c_sg = [],
    cts_keyword = [],
    cts_keyword_list = [],
    cts_iab_category = [],
  } = variables;

  return {
    page: {
      type: page_type[0],
      article: {
        id: entry_id[0],
        title: entry_title[0],
        description: entry_blurb[0],
        authors: entry_author,
        section: entry_group[0],
        publishedAt: entry_published_date[0],
        keywords: typeof keywords === 'string' ? keywords.split(' ') : keywords,
      },
      browser_language: window.navigator.language || '',
      contextClassified: {
        type: 'Interest',
        id: c_sg,
      },
      cts: {
        keyword: cts_keyword,
        keywordList: cts_keyword_list,
        iabCategory: cts_iab_category,
      },
    },
  };
}

/**
 * Add code provided by Permutive
 * @param {String} projectId      Permutive Project ID
 * @param {String} publicApiKey   Permutive Public API key
 * @return {undefined}
 */
function addPermutiveProvidedCode(projectId, publicApiKey) {
  !(function(n, e, o, r, i) {
    if (!e) {
      (e = e || {}),
        (window.permutive = e),
        (e.q = []),
        (e.config = i || {}),
        (e.config.projectId = o),
        (e.config.apiKey = r),
        (e.config.environment = e.config.environment || 'production');
      for (
        var t = [
            'addon',
            'identify',
            'track',
            'trigger',
            'query',
            'segment',
            'segments',
            'ready',
            'on',
            'once',
            'user',
            'consent',
          ],
          c = 0;
        c < t.length;
        c++
      ) {
        var f = t[c];
        e[f] = (function(n) {
          return function() {
            var o = Array.prototype.slice.call(arguments, 0);
            e.q.push({ functionName: n, arguments: o });
          };
        })(f);
      }
    }
  })(document, window.permutive, projectId, publicApiKey, {});
}
