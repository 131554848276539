import Logger from '../lib/logger';

export default class BidManager {
  /**
   * The BidManager class communicates with our bidding partners, namely two:
   * 1. Prebid.js (which encapsulates many bidding partners)
   * 2. Amazon (which has its own header bidding system, for more money)
   *
   * BidManager accepts instances of the Bid class, and it queues them until they
   * are ready to be fetched. On page load, we wait to define all the slots on the
   * page, queue up their bids, and request them in one batch. As slots refresh
   * further down the page, their bids will be fetched on an individual basis
   * instead of in bulk.
   */
  constructor({ app }) {
    this.app = app;
    this.dependenciesResolved = true;
    this.readyToBeBid = [];
    this._numberOfBidsFetched = 0;
    this.activelyBidding = [];
    this.biddingPartners = [];
    this.beforeFirstBidQueue = [];
    this.bidElements = new Map();
    this.fetchTimer = 0;

    this.observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          const slot = entry.target;

          if (entry.intersectionRatio > 0.0 || entry.isIntersecting) {
            const bidForSlot = this.bidElements.get(slot);
            this.enqueueBid(bidForSlot);
            observer.unobserve(slot);
          }
        });
      },
      {
        rootMargin: this.app.settings.prebid.observerThreshold || '300px',
      }
    );
  }

  get prebidTimeout() {
    return this.app.settings.prebid.auctionTimeout || 2000;
  }

  get shouldLazyBid() {
    return Boolean(this.app.settings.lazyBid || this.app.settings.lazy_bid); // Temporary, to support configs until they are deployed
  }

  /**
   * Add a bid to the queue to be fetched
   * @param {Bid} bid  Bid instance
   */
  addBid(bid) {
    if (!this.shouldLazyBid || !bid.slot.isWatcherEligible()) {
      this.enqueueBid(bid);
      Logger.log(`Lazy loading of bids is not enabled for ${bid.slot.name}.`);
      return;
    }

    Logger.log(
      `Lazy loading of bids is enabled for ${bid.slot.name}, bids will be requested as slot approaches viewport.`
    );

    this.bidElements.set(bid.slot.element, bid);
    if (bid.slot.element) {
      this.observer.observe(bid.slot.element);
    } else {
      console.error('Could not find element:', bid.slot.element);
    }
  }

  enqueueBid(bid) {
    this.readyToBeBid.push(bid);

    clearTimeout(this.fetchTimer);

    this.fetchTimer = setTimeout(() => {
      this.fetchBids();
    }, this.app.settings.prebid.coalesceDelay || 50);
  }

  /**
   * Indiciates if we can fetch the bids yet, or if there are still
   * dependencies to resolve before a fetch can happen
   *
   * @return {Boolean}
   */
  canFetchBids() {
    return this.dependenciesResolved;
  }

  /**
   * Listen for the initial group of ads to get defined, so we can make one
   * request in bulk.
   */
  async init() {
    // resolve all dependencies that have been enqeued before the first bid can run
    await Promise.allSettled(
      this.beforeFirstBidQueue.map(dependencyFunction => {
        return dependencyFunction();
      })
    ).then(results => {
      Logger.log(
        `First bid dependencies were settled with the statuses: ${JSON.stringify(results.map(result => result.status))}`
      );
    });

    this.dependenciesResolved = true;
    Logger.log('Calling initial request for bids');
    this.fetchBids();
  }

  /**
   * Add dependencies to be run before the first bid can be returned
   * If passed a function that returns a promise it will prevent bids from
   * fetching until promise is resolved
   * @param {Function} dependencyFunction a function, can return a promise
   */
  beforeFirstBid(dependencyFunction) {
    this.dependenciesResolved = false;
    this.beforeFirstBidQueue.push(dependencyFunction);
  }

  /**
   * Add bidding partner
   * Used within the Prebid, Rubicon Demand Manager, and Amazon plugins
   */
  addBiddingPartner(partner) {
    this.biddingPartners.push(partner);
  }

  /**
   * Fetch bids from bidding partners, and trigger callbacks.
   * This is only called externally during tests.
   * @param  {function} Optional callback method, used during tests.
   * @return {undefined}
   */
  fetchBids(callback = null) {
    if (!this.canFetchBids()) {
      return false;
    }

    if (!this.readyToBeBid.length) {
      if (callback) {
        callback();
      }
      return;
    }

    if (this.activelyBidding.length) {
      Logger.log('Bid Manager defering fetchBids because some bids are currently being handled');
      return;
    }

    this.activelyBidding = this.readyToBeBid;
    this.readyToBeBid = [];
    this._numberOfBidsFetched += this.activelyBidding.length;

    Logger.log('Fetching bids from all bidding partners');

    Promise.all(
      this.biddingPartners.map(partner => {
        return partner.fetchBidsFor({ queueOfBids: this.activelyBidding, timeout: this.prebidTimeout });
      })
    ).then(() => {
      Logger.log('Bids have been fetched for bidding partners');
      this.biddingPartners.forEach(partner => {
        if (typeof partner.addTargeting === 'function') {
          partner.addTargeting({ queueOfBids: this.activelyBidding });
        }
      });

      this.activelyBidding.forEach(bid => bid.slot.bidded());

      // Send a callback for tests
      if (callback) callback();
      this.activelyBidding = [];

      // Fetch bids, if some have been set to ready
      if (this.readyToBeBid.length) {
        Logger.log('Bid Manager done fetching, but going to run again because some are in the queue');
        this.fetchBids();
      }
    });
  }

  /**
   * Returns the number of bids waiting to be fetched
   * @return {Number} bids waiting to be fetched
   */
  numberofBidsReadyToBeBid() {
    return this.readyToBeBid.length;
  }

  /**
   * Returns the total count of bids fetched this session
   * useful for debugging and testing.
   * @return {Number} the number of bids fetched this session.
   */
  numberOfBidsFetched() {
    return this._numberOfBidsFetched;
  }

  /**
   * Disable bid manager while slots are re-added.
   */
  disable() {
    this.dependenciesResolved = false;
  }
}
