/* eslint-disable camelcase */

import Logger from '../logger';
import Slot from '../slot';

const REVERB_BASE_URL = 'https://cdn.concert.io/reverb-video/';

export default class ReverbSlot extends Slot {
  constructor(app, data) {
    super(app, data);
  }

  applyDefaults() {
    super.applyDefaults({
      state: {
        reverbGlobal: this.global,
        reverbScript: this.scriptUrl,
      },
    });
  }

  /**
   * Show an ad in this slot
   * @return {undefined}
   */
  render() {
    if (!super.render()) return;

    Logger.log(`Loading ${this.name}`);

    // Make sure Reverb isn't already loaded
    if (!window[this.global]) {
      this.loadReverb();
    }
  }

  /**
   * Determine whether this slot can be displayed on the page
   * @return {boolean}
   */
  canBeDisplayed() {
    return super.canBeDisplayed() && this.data.reverbPlayer;
  }

  /**
   * Load Reverb script
   * @return {undefined}
   */
  loadReverb() {
    window[this.global] = {};
    window[this.global].cmd = [];

    const container = document.createElement('div');
    container.id = 'reverb-main';
    this.element.appendChild(container);

    const playerScript = document.createElement('script');
    playerScript.type = 'text/javascript';
    playerScript.src = this.scriptUrl;

    playerScript.id = this.data.scriptId;
    this.element.appendChild(playerScript);
  }

  get global() {
    return 'reverb-video';
  }

  get scriptUrl() {
    if (!this.data.reverbPlayer) {
      throw 'Reverb Player not defined. This value must be specified in the config';
    }
    return `${REVERB_BASE_URL}${this.data.reverbPlayer}.js`;
  }

  static isSlotTypeFor(config) {
    return config.type === 'reverb';
  }
}
