import Logger from '../lib/logger';
import PluginBase from './base';
import PrebidHelper from '../lib/prebid_helper';
import * as prebidFunctions from '../lib/prebid_helper';
import PrebidEvents from '../lib/prebid_events';

export default class PrebidBidding extends PluginBase {
  onSettingsLoaded() {
    if (this.app.settings.prebid.biddingEnabled && this.app.settings.prebid.prebidEnabled) {
      Logger.log('Installing plugin: Prebid Bidding');

      if (!this.app.hasReinstalled) {
        // We need to add this webpack comment for us to import the file dynamically
        // https://medium.com/front-end-weekly/webpack-and-dynamic-imports-doing-it-right-72549ff49234
        import(/* webpackExports: ["default", "prebid"] */
        /* webpackMode: "lazy" */
        '../vendor/prebid-v2/prebid')
          .then(() => {
            this.prebidHelper = new PrebidHelper(this);
            this.prebidHelper.configure(this.app);
            this.setupAndListenForPrebidEvents();
          })
          .catch(e => {
            Logger.error(`The vendor Prebid script could not be loaded due to the following error: ${e}`);
          });
      }
    }
  }

  onBidManagerCreated() {
    if (this.app.settings.prebid.biddingEnabled && this.app.settings.prebid.prebidEnabled) {
      this.app.bidManager.addBiddingPartner(this);
    }
  }

  /**
   * Setting up Prebid Events so we can listen to them
   */
  setupAndListenForPrebidEvents() {
    Logger.log('Prebid events are being set up.');
    this.app.prebidEvents = new PrebidEvents({ app: this.app });
    prebidFunctions.listenForEvents(this.app);
  }

  /**
   * Build array of objects ready for Prebid from queued bids
   */
  buildBiddingQueues(queue) {
    const biddingQueue = [];

    queue.forEach(bid => {
      biddingQueue.push({
        code: bid.slot.name,
        mediaTypes: {
          banner: {
            sizes: bid.slot.sizes,
          },
        },
        bids: bid.config,
      });
    });

    return biddingQueue;
  }

  /**
   * Fetch bids from Prebid.
   * @type {Promise}
   */
  fetchBidsFor({ queueOfBids, timeout }) {
    if (!prebidFunctions.prebidScriptLoaded()) return;

    return new Promise(resolve => {
      Logger.log('Fetching bids with Prebid');

      pbjs.requestBids({
        timeout,
        adUnits: this.buildBiddingQueues(queueOfBids),
        bidsBackHandler: resolve,
      });
    }).catch(() => {
      Logger.log('There was an error fetching bids with Prebid');
    });
  }

  /**
   * Add Prebid targeting to bids
   */
  addTargeting({ queueOfBids }) {
    if (!prebidFunctions.prebidScriptLoaded()) return;
    Logger.log('Adding Prebid targeting to bids.');
    queueOfBids.forEach(bid => bid.addTargeting());
  }
}
