import store from './store';
import { isObject } from './utils';

export class CascadingConfig {
  constructor(storageKey) {
    if (!storageKey) {
      throw new Error('A storage key is required.');
    }

    this.storageKey = storageKey;
    this.set({});
  }

  /**
   * Add a value to the config.
   *
   * @param {any} value Value to add to the config
   */
  add(value) {
    this.clearCache();
    store.append(this.storageKey, value);
  }

  /**
   * Overwrite any given value and set the initial value for the store.
   * Like add(), except no previous values are kept.
   *
   * @param {any} value Value to set as the initial value
   */
  set(value) {
    this.clearCache();
    store.set(this.storageKey, [value]);
  }

  /**
   * Get the config (or a top-level value of the config).
   *
   * @param {string?} key (optional) Top-level key to fetch. Returns entire object if no key provided.
   */
  get(key) {
    return key ? this.resolve()[key] : this.resolve();
  }

  /**
   * Deep-merge all the config values and return the computed value.
   */
  resolve() {
    if (this.cache) return this.cache;

    const values = store.get(this.storageKey);
    this.cache = mergeDeep(...(values || []));

    return this.cache;
  }

  clearCache() {
    delete this.cache;
  }
}

/**
 * Performs a deep merge of objects and returns new object. Does not modify
 * objects (immutable) and merges arrays via concatenation.
 *
 * @author [jhildenbiddle](https://stackoverflow.com/a/48218209)
 * @param {...object} objects - Objects to merge
 * @returns {object} New object with merged key/values
 */
function mergeDeep(...objects) {
  return objects.reduce((prev, obj) => {
    Object.keys(obj).forEach(key => {
      const pVal = prev[key];
      const oVal = obj[key];

      if (Array.isArray(pVal) && Array.isArray(oVal)) {
        prev[key] = pVal.concat(...oVal);
      } else if (isObject(pVal) && isObject(oVal)) {
        prev[key] = mergeDeep(pVal, oVal);
      } else {
        prev[key] = oVal;
      }
    });

    return prev;
  }, {});
}
