import Logger from '../logger';
import Slot from '../slot';
import ScriptLoader from '../script_loader';

export default class CivicScienceSlot extends Slot {
  constructor(app, data) {
    super(app, data);

    this.civicScienceElement = document.createElement('div');
  }

  /**
   * Show an ad in this slot
   * @return {undefined}
   */
  render() {
    if (!super.render()) return;

    Logger.log(`Rendering ${this.name}`);

    this.setUpCivicScience();
    this.appendPixel();
    this.loadScript();
  }

  /**
   * Determine whether this slot can be displayed on the page
   * @return {boolean}
   */
  canBeDisplayed() {
    return (
      super.canBeDisplayed() &&
      this.app.settings.civicScience?.id &&
      this.app.settings.civicScience?.scriptUrl &&
      this.app.settings.civicScience?.widget
    );
  }

  /**
   * Add required attributes to Civic Science wrapper element
   * @return {undefined}
   */
  setUpCivicScience() {
    const attributes = {
      id: this.app.settings.civicScience?.id,
      'data-civicscience-widget': this.app.settings.civicScience?.widget,
    };

    Object.keys(attributes).forEach(attribute =>
      this.civicScienceElement.setAttribute(attribute, attributes[attribute])
    );

    this.element.appendChild(this.civicScienceElement);
  }

  destroyWrapper() {
    this.wrapper.destroy();
  }

  appendPixel() {
    try {
      const userId = localStorage.getItem('permutive-id');
      const partnerShortIdentifier = 'vox';
      const img = document.createElement('img');

      const attributes = {
        src: `https://www.civicscience.com/idsync/3?uid=${userId}&p=${partnerShortIdentifier}`,
        width: 0,
        height: 0,
      };

      Object.keys(attributes).forEach(attribute => {
        img.setAttribute(attribute, attributes[attribute]);
      });

      this.element.appendChild(img);
    } catch (e) {
      Logger.error(`There was an error setting up the ID Sync pixel for Civic Science: ${e}`);
    }
  }

  /**
   * Load CivicScience script
   * @return {undefined}
   */
  loadScript() {
    const dependency = {
      url: this.app.settings.civicScience?.scriptUrl,
      timeout: 3000,
      parentElement: this.civicScienceElement,
    };

    new ScriptLoader()
      .load(dependency)
      .then(url => {
        Logger.log(`${this.name} script ${url} has loaded successfully`);
        this.rendered();
        this.markAsDisplayed();
      })
      .catch(error => {
        Logger.log(`Error loading ${this.name} script: ${error}`);
        Logger.log(`${this.name} has been collapsed.`);
        this.wrapper.collapseSlotWrapper();
        this.collapsed();
      });
  }

  static isSlotTypeFor(config) {
    return config.type === 'civic-science';
  }
}
