export const DEFAULT_FORCE_SAFEFRAME = false;
export const DEFAULT_SAFEFRAME_SETTINGS = {};

/**
 * Determine whether force safeframe is enabled
 *
 * @param {{ settings: any }} settings  Settings object
 * @param {string?}           url       URL to test (optional)
 * @returns {boolean} force safeframe, if required.
 */
export function forceSafeframe({ settings }, url = null) {
  const forceSafeframeFromSettings =
    settings.forceSafeframe !== undefined ? settings.forceSafeframe : DEFAULT_FORCE_SAFEFRAME;
  const forceSafeframeFromUrl = getForceSafeframeFromUrl(url);

  return forceSafeframeFromSettings || forceSafeframeFromUrl;
}

/**
 * Returns the safeframe settings object for GPT
 * https://developers.google.com/doubleclick-gpt/reference#googletag.PubAdsService_setSafeFrameConfig
 *
 * @param {{ settings: any }} settings  Settings object
 * @returns {object}  safeframe settings
 */
export function safeframeSettings({ settings }) {
  return settings.safeframeSettings !== undefined ? settings.safeframeSettings : DEFAULT_SAFEFRAME_SETTINGS;
}

/**
 * You can force safeframe in the url by passing ?concert_ads_force_safeframe=true in the query.
 *
 * @param {string?} url  URL to test (optional)
 * @returns {int|null}
 */
function getForceSafeframeFromUrl(url) {
  const safeframe = (url || window.location.toString()).match(/concert_ads_force_safeframe/);

  return !!safeframe;
}
