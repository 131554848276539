export default class SlotNames {
  constructor() {
    /**
     * Slot Names keep a simple counter of how many times a slot name has
     * been used.
     *
     * @type {Object}
     */
    this._names = {};
  }

  /**
   * See if the slot name has been registered
   * @param  {string}  name Slot name
   * @return {Boolean}
   */
  exists(name) {
    return !!this._names[name];
  }

  /**
   * Get the count of a given slot name
   * @param  {string} name Slot Name
   * @return {int}
   */
  getCount(name) {
    return this._names[name];
  }

  /**
   * Increment a slot name's counter. Sets to 1 if it doesn't yet exist.
   * @param  {string} name Name
   * @return {int}         The new count
   */
  increment(name) {
    this._names[name] = this._names[name] + 1 || 1;

    return this._names[name];
  }

  /**
   * Reset the slot count. Only used for testing.
   */
  reset() {
    this._names = {};
  }
}
