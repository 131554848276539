import Constants from './constants';
import pushToDataLayer from '../external/data_layer';
import Logger from './logger';

export default class Metrics {
  /**
   * Wrapper for sending metrics to google analytics
   *
   * @param  {string} key   The name of the key to send med_rec:loaded
   * @param  {*} value   The value of this event
   */
  static track(key, value) {
    Logger.log(`Record ${key} with value ${value} (${Constants.SHOULD_SEND_METRICS ? 'sent' : 'not sent'})`);
    if (Constants.SHOULD_SEND_METRICS) {
      // Note: This could use the analytics lib in Presto, but that relies on
      // jQuery, which we do not want to add into this library. Since this is
      // temporary, we are just going to push to dataLayer for this one thing.
      pushToDataLayer({
        event: 'analyticsEvent',
        eventAction: key,
        eventCategory: 'ads',
        eventLabel: value,
        eventNonInt: null,
        eventValue: null,
      });
    }
  }
}
