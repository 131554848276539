/* eslint-disable camelcase */

import Logger from '../logger';
import Slot from '../slot';
import ScriptLoader from '../script_loader';

export default class ConnatixSlot extends Slot {
  constructor(app, data) {
    super(app, data);
  }

  applyDefaults() {
    super.applyDefaults({
      state: {
        connatixGlobal: this.global,
        connatixScript: this.scriptUrl,
      },
    });
  }

  /**
   * Show an ad in this slot
   * @return {undefined}
   */
  render() {
    if (!super.render()) return;

    Logger.log(`Rendering Connatix Slot: ${this.name}`);

    if (this.data.deferInitLoad) {
      Logger.log(`Deferring Connatix Slot load for ${this.data.deferInitLoad}ms`);
      setTimeout(() => {
        Logger.log(`Loading deferred Connatix Slot: ${this.name}`);
        this.loadConnatixAssets();
      }, this.data.deferInitLoad);
    } else {
      this.loadConnatixAssets();
    }
  }

  loadConnatixAssets() {
    if (!window[this.global]) {
      Logger.log(`Loading Connatix script for slot: ${this.name}`);
      this.loadConnatixScriptAndPlayer();
    } else {
      Logger.log(`Connatix script already loaded for slot: ${this.name}`);
      this.loadConnatixPlayer();
    }
  }

  /**
   * Determine whether this slot can be displayed on the page
   * @return {boolean}
   */
  canBeDisplayed() {
    return (
      super.canBeDisplayed() &&
      this.data.playerId &&
      this.data.scriptId &&
      (this.data.connatixPlayer || this.app.settings.connatixPlayer)
    );
  }

  /**
   * Load Connatix script and player
   * @return {undefined}
   */
  loadConnatixScriptAndPlayer() {
    window[this.global] = {};
    window[this.global].cmd = [];

    const frame = document.createElement('iframe');
    frame.style.display = 'none';
    frame.addEventListener('load', () => {
      const frameBody = frame.contentWindow.document.body;

      const dependency = {
        url: this.scriptUrl,
        timeout: this.app.settings.connatixScriptTimeout || 3000,
        parentElement: frameBody,
      };

      new ScriptLoader()
        .load(dependency)
        .then(url => {
          Logger.log(`${this.name} script ${url} has loaded successfully`);
          this.rendered();
          this.markAsDisplayed();
          this.loadConnatixPlayer();
        })
        .catch(error => {
          Logger.log(`Error loading ${this.name} script: ${error}`);
          Logger.log(`${this.data.name} has been collapsed.`);
          this.wrapper.collapseSlotWrapper();
          this.collapsed();
        });
    });

    this.element.appendChild(frame);
  }

  /**
   * Load and render Connatix Player
   * @return {undefined}
   */
  loadConnatixPlayer() {
    this.appendPlayerScript();
    this.renderConnatixPlayer();
  }

  /**
   * Append script that provides location of player
   * @return {undefined}
   */
  appendPlayerScript() {
    const playerScript = document.createElement('script');

    playerScript.id = this.data.scriptId;

    if (this.data.connatixPlayerUrl) {
      playerScript.src = this.data.connatixPlayerUrl;
      playerScript.async = true;
    }

    this.element.appendChild(playerScript);
  }

  /**
   * Render Connatix Player
   * @return {undefined}
   */
  renderConnatixPlayer() {
    if (this.data.connatixPlayerUrl) {
      Logger.log(`Connatix Player URL provided, no manual render needed for slot: ${this.name}`);
      return;
    }

    Logger.log(`Rendering Connatix Player for slot: ${this.name}`);

    Promise.all(this.app.beforeAdsRequested).then(() => {
      const permutiveTargeting = window.localStorage.getItem('_pdfps');
      const {
        entry_type = '',
        entry_group = Array.isArray(entry_group) ? [''] : '',
        cts_keyword = [''],
        cts_keyword_list = [''],
        cts_iab_category = [''],
        affiliation = [''],
        page_type = Array.isArray(page_type) ? [''] : '',
        cts_present = Number(cts_present),
      } = this.app.variables;

      window[this.global].cmd.push(() => {
        const tagTargeting =
          ['basketball', 'baseball', 'ice-hockey', 'nfl', 'college-football', 'soccer', 'mma'].find(aff =>
            affiliation.includes(aff)
          ) || '';
        const trackerImg = new Image();
        trackerImg.src = `https://capi.connatix.com/tr/si?token=${this.data.playerId}`;
        const pageloadId = this.app.variables.pageload_id || '';

        window[this.global]({
          playerId: this.data.playerId,
          customParam1: `pageload_id: ${pageloadId}`,
          settings: {
            advertising: {
              macros: {
                entry_type: entry_type[0] || entry_type,
                entry_group: entry_group,
                cts_keyword: cts_keyword,
                cts_keyword_list: cts_keyword_list,
                cts_iab_category: cts_iab_category,
                permutive: permutiveTargeting ? JSON.parse(permutiveTargeting) : [''],
                page_type: page_type,
              },
            },
            queryJsTargeting: {
              TagTargeting: tagTargeting,
            },
          },
        }).render(this.data.scriptId);
      });
    });
  }

  get playerType() {
    return this.data.connatixPlayer || this.app.settings.connatixPlayer || 'full';
  }

  get global() {
    return 'cnx';
  }

  get scriptUrl() {
    return this.data.connatixScriptUrl || this.defaultScriptUrl;
  }

  get defaultScriptUrl() {
    return 'https://cd.connatix.com/connatix.player.js';
  }

  static isSlotTypeFor(config) {
    return config.type === 'connatix';
  }
}
