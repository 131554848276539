import { getSessionDepth, getDeviceType } from '../lib/utils';

/**
 * @file The filters we use for dynamic slot insertion.
 * Each filter takes in as an argument an element, which will be the neighbor
 * element brought in by querySelectorAll. The rest of the arguments are what
 * is passed in fron the dynamic slot config's options.
 */

const rand = Math.random();

export default {
  /**
   *
   * @param  {Object} el        The neighbor element.
   * @param  {Number} threshold A number between 0-1 of to match the percentage
   *                            of times you want this to succeed.
   * @return {Boolean}          Whether or not the slot should be put there.
   */
  random: function(el, threshold) {
    return rand < threshold;
  },

  /**
   * Determine if the slot can be placed based on the viewport width.
   * @param  {Object} el      The neighbor element.
   * @param  {Object} options Object with two available options, min and max.
   * @return {Boolean}        Whether or not the slot should be put there.
   */
  viewportWidth: function(el, options = {}) {
    var min = options.min || 0;
    var max = options.max || Infinity;
    var width = window.innerWidth;
    return width >= min && width < max;
  },

  /**
   * Determine if the slot can be placed based on the viewport height.
   * @param  {Object} el      The neighbor element.
   * @param  {Object} options Object with two available options, min and max.
   * @return {Boolean}        Whether or not the slot should be put there.
   */
  viewportHeight: function(el, options = {}) {
    var min = options.min || 0;
    var max = options.max || Infinity;
    var height = window.innerHeight;
    return height >= min && height < max;
  },

  /**
   * Can the slot fit within the container above?
   * @param  {Object} el      The neighbor element.
   * @param  {Object} options Object with two available options, min and max.
   * @return {Boolean}        Whether or not the slot should be put there.
   */
  containerWidth: function(el, options = {}) {
    var min = options.min || 0;
    var max = options.max || Infinity;
    var width = el.parentNode.clientWidth;
    return width > min && width < max;
  },

  /**
   * Does the UserAgent contain certain values.
   * @param  {Object} el      The neighbor element.
   * @param  {Object} options An object with available options to test by.
   *                          Currently only "includes", a string.
   * @return {Boolean}        Whether or not the slot should be put there.
   */
  userAgent: function(el, opts = {}) {
    return opts.includes && navigator.userAgent.indexOf(opts.includes) > -1;
  },

  /**
   * Does the referrer include a certain string?
   * @param  {Object} el      The neighbor element.
   * @param  {Object} options An object of options. Includes is the only value
   *                          allowed.
   * @return {Boolean}        Whether or not the slot should be put there.
   */
  referrer: function(el, options = {}) {
    var includes = options.includes || undefined;
    return includes !== undefined && document.referrer.indexOf(includes) > -1;
  },

  /**
   * [pagesThisSession description]
   * @param  {Object} el      The neighbor element.
   * @param  {Object} options An object of options to check against.
   *                          Before/after are the two values allowed.
   * @return {Boolean}        Whether or not the slot should be put there.
   */
  pagesThisSession: function(el, options = {}) {
    var sessionDepth = getSessionDepth();
    var before = options.before || undefined;
    var after = options.after || undefined;
    return after <= sessionDepth || before > sessionDepth;
  },

  /**
   * Make sure that there's enough height in the neighboring paragraphs to place the slot.
   * @param  {Object} el      The neighbor element.
   * @param  {Object} options Object with two available options: above, below
   * @return {Boolean}        Whether or not there is room for the slot.
   */
  paragraphHeight: function(el, options = {}) {
    var safeList = 'p, h1, h2, h3, h4, h5, h6, ul, li, ol, div.duet--article--article-body-component';
    var directions = Object.keys(options);

    var allClear = directions.every(dir => {
      var r = document.createRange();

      if (dir === 'above') {
        r.setEndBefore(el);
        var prevGraf = el;

        while (prevGraf.previousElementSibling && prevGraf.previousElementSibling.matches(safeList)) {
          prevGraf = prevGraf.previousElementSibling;
        }

        r.setStartBefore(prevGraf);
      } else if (dir === 'below') {
        r.setStartBefore(el);
        var nextGraf = el;

        while (nextGraf.nextElementSibling && nextGraf.nextElementSibling.matches(safeList)) {
          nextGraf = nextGraf.nextElementSibling;
        }

        r.setEndAfter(nextGraf);
      }

      return r.getBoundingClientRect().height >= options[dir];
    });

    return allClear;
  },

  /**
   * Is there enough space above and below the slot?
   * @param  {Object} el      The neighbor element.
   * @param  {Object} options An object of options to check against.
   *                          Accepts values:
   *                            before // e.g., before: 700
   *                            after // e.g., after: 500
   *                            conditional:
   *                              condition:
   *                                useIfSubscription: true // or useIfSelector: '.special-class'
   *                              before: 800
   *                              after: 800
   *                            exceptSlot // e.g., exceptSlot: native_quicklistings
   * @return {Boolean}        Whether or not the slot should be put there.
   */
  spacing: function(el, options = {}, settings = {}) {
    const range = document.createRange();
    const before = getSpacingValue('before', options);
    const after = getSpacingValue('after', options);

    if (before) {
      var prevAd = el.previousElementSibling;

      while (prevAd && isNotAd(prevAd.className)) {
        prevAd = prevAd.previousElementSibling;
      }
      if (prevAd) {
        range.setStartAfter(prevAd);
      } else {
        range.setStart(el.parentNode, 0);
      }
      range.setEndBefore(el);
      if (estimateHeight(range) < before) {
        return false;
      }
    }

    if (after) {
      var nextAd = el.nextElementSibling;

      while (nextAd && isNotAd(nextAd.className)) {
        nextAd = nextAd.nextElementSibling;
      }
      if (nextAd) {
        range.setEndBefore(nextAd);
      } else {
        range.setEndAfter(el.parentNode.lastChild);
      }
      range.setStartBefore(el);
      if (estimateHeight(range) < after) {
        return false;
      }
    }

    return true;

    function estimateHeight(range) {
      return range.getBoundingClientRect().height;
    }

    function isNotAd(className) {
      if (options.exceptSlot && className.indexOf(options.exceptSlot) > -1) {
        return true;
      } else {
        return !/\bm-ad\b/.test(className);
      }
    }

    function getSpacingValue(beforeOrAfter, options) {
      const conditional = options.conditional;
      if (conditionExists(conditional, settings)) {
        return conditional[beforeOrAfter] || options[beforeOrAfter];
      }

      return options[beforeOrAfter];
    }

    function conditionExists(conditional, settings) {
      try {
        if (conditional && typeof conditional.condition === 'object') {
          const conditionType = Object.keys(conditional.condition)[0];

          if (conditionType === 'useIfSubscription') {
            return Boolean(settings.hasSubscription);
          } else if (conditionType === 'useIfSelector') {
            return Boolean(document.querySelector(conditional.condition[conditionType]));
          } else {
            return false;
          }
        }
        return false;
      } catch (e) {
        return false;
      }
    }
  },

  /**
   * We hide the slot if the selector passed exists on the page.
   * @param  {Object} el           The neighbor element.
   * @param  {Object} [options={}] An object of options to check against.
   *                               selector: string
   *                               count: number (defaults to 1)
   * @return {Boolean}             Whether or not the slot should be put there.
   */
  hideIfPresent: function(el, options = {}) {
    const selector = options.selector || '';
    const count = parseInt(options.count) || 1;
    return document.querySelectorAll(selector).length < count;
  },

  /**
   * We don't show the slot if the selector passed is within the before/after range supplied.
   * @param  {Object} el           The neighbor element.
   * @param  {Object} [options=[]] An array of options objects to check against.
   *                               Each option object should contain
   *                               selector: string
   *                               after: number (defaults to 0)
   *                               before: number (defaults to 0)
   * @return {Boolean}             Whether or not the slot should be put there.
   */
  avoidElementsWithin: function(el, options = []) {
    if (Array.isArray(options)) {
      for (let i = 0; i < options.length; i++) {
        if (selectorOverlapsEl(options[i], el)) {
          return false;
        }
      }
      return true;
    }
    return !selectorOverlapsEl(options, el);
  },

  /**
   * We show the slot if the selector passed exists on the page.
   * @param  {Object} el           The neighbor element.
   * @param  {Object} [options={}] An object of options to check against.
   *                               selector: string
   *                               count: number (defaults to 1)
   * @return {Boolean}             Whether or not the slot should be put there.
   */
  showIfPresent: function(el, options = {}) {
    const selector = options.selector || '';
    const count = parseInt(options.count) || 1;

    return document.querySelectorAll(selector).length >= count;
  },

  /**
   * We hide the slot if the user has a subscription
   * @param  {Object} el           The neighbor element.
   * @param  {Object} [options={}] An object of options to check against.
   * @param  {Object} [settings={}] The app settings object
   * @return {Boolean}             Whether or not the slot should be put there.
   */
  hideIfSubscription: function(el, options, settings = {}) {
    return !settings.hasSubscription;
  },

  /**
   * We show the slot if the user has a subscription
   * @param  {Object} el           The neighbor element.
   * @param  {Object} [options={}] An object of options to check against.
   * @param  {Object} [settings={}] The app settings object
   * @return {Boolean}             Whether or not the slot should be put there.
   */
  showIfSubscription: function(el, options, settings = {}) {
    return Boolean(settings.hasSubscription);
  },

  /**
   * We hide the slot if the paywall is active
   * @param  {Object} el           The neighbor element.
   * @param  {Object} [options={}] An object of options to check against.
   * @param  {Object} [settings={}] The app settings object
   * @return {Boolean}             Whether or not the slot should be put there.
   */
  hideIfPaywall: function(el, options, settings = {}) {
    return !settings.paywallActive;
  },

  /**
   * We hide the slot if the user is logged in
   * @param  {Object} el           The neighbor element.
   * @param  {Object} [options={}] An object of options to check against.
   * @param  {Object} [settings={}] The app settings object
   * @return {Boolean}             Whether or not the slot should be put there.
   */
  hideIfLoggedIn: function(el, options, settings = {}) {
    return !settings.loggedIn;
  },

  /**
   * We show the slot if the user is logged in or if the paywall is active
   * @param  {Object} el           The neighbor element.
   * @param  {Object} [options={}] An object of options to check against.
   * @param  {Object} [settings={}] The app settings object
   * @return {Boolean}             Whether or not the slot should be put there.
   */
  showIfLoggedInOrPaywall: function(el, options, settings = {}) {
    return Boolean(settings.loggedIn) || Boolean(settings.paywallActive);
  },

  /**
   * Be able to avoid any flots within the DOM near where we were gonna put the
   * slot.
   * @param  {Object} el The neighbor element.
   * @return {Boolean}   Whether or not the slot should be put there.
   */
  avoidFloats: function(el) {
    const { top, bottom } = el.getBoundingClientRect();

    // Check the given element's top/bottom coordinates against floated siblings.
    // Specifically, if the top and bottom of the element are above the top of
    // the sibling, or if the top is below the bottom of the sibling, it is
    // marked as having avoided a float and returns true.
    for (let [fTop, fBottom] of getFloatedSiblingPositions(el)) {
      if (!((top < fTop && bottom < fTop) || top > fBottom)) {
        return false;
      }
    }

    return true;
  },

  /**
   * Allows us to avoid inserting before or after elements with
   * specified ids, classNames, or of a certain type
   * @param {Object} el the neighbor element
   * @param {Object} options the options object
   * @returns {Boolean}
   */
  avoidElements: function(el, options = {}, settings, config) {
    let beforeSibling;
    let afterSibling;
    if (config.insertion === 'inside') {
      beforeSibling = el.lastElementChild;
      afterSibling = el.nextElementSibling;
    } else if (config.insertion === 'after') {
      beforeSibling = el;
      afterSibling = el.nextElementSibling;
    } else {
      beforeSibling = el.previousElementSibling;
      afterSibling = el;
    }

    if (options.before) {
      const beforeSiblingCheck = checkSiblingElementSelectors(options.before, beforeSibling);
      if (!beforeSiblingCheck) return false;
    }

    if (options.after) {
      const afterSiblingCheck = checkSiblingElementSelectors(options.after, afterSibling);
      if (!afterSiblingCheck) return false;
    }

    return true;

    function checkSiblingElementSelectors(option, sibling) {
      if (!sibling) return false;
      if (sibling.classList.contains(option.className)) return false;
      if (sibling.id === option.id) return false;
      if (sibling.nodeName === option.type?.toUpperCase()) return false;
      return true;
    }
  },

  /**
   *
   * @param  {Node}   node             Current insertion node candidate.
   * @param  {Object} options          Filter arguments. Selector + min OR max required.
   * @param  {string} options.selector Valid CSS selector for element.
   * @param  {number} [options.min]    Minimum allowed height of element.
   * @param  {number} [options.max]    Maximum allowed height of element.
   * @return {Boolean}
   */
  elementHeight(node, { selector, min, max }) {
    const el = document.querySelector(selector);

    // If the element can't be found, and neither a min OR max was provided, fail.
    if (!el || (!min && !max)) return false;

    const height = el.getBoundingClientRect().height;

    // Check for a min, a max, or both.
    if (min && height < parseInt(min)) return false;
    if (max && height > parseInt(max)) return false;

    return true;
  },

  /**
   *
   * @param  {Node}   node             Current insertion node candidate.
   * @param  {Object} options          Filter arguments. Selector + min OR max required.
   * @param  {string} options.selector Valid CSS selector for element.
   * @param  {number} [options.min]    Minimum allowed width of element.
   * @param  {number} [options.max]    Maximum allowed width of element.
   * @return {Boolean}
   */
  elementWidth(node, { selector, min, max }) {
    const el = document.querySelector(selector);

    // If the element can't be found, and neither a min OR max was provided, fail.
    if (!el || (!min && !max)) return false;

    const width = el.getBoundingClientRect().width;

    // Check for a min, a max, or both.
    if (min && width < parseInt(min)) return false;
    if (max && width > parseInt(max)) return false;

    return true;
  },

  /**
   * Only show the slot if the hostname passed matches the page.
   * @param  {Object} el           The neighbor element.
   * @param  {Object} [options={}] An object of options to check against.
   *                               Accepts one or more hostnames as comma-separated strings
   *                               e.g., vox.com, miami.curbed.com, curbed.com
   * @return {Boolean}             Whether or not the slot should be put there.
   */
  matchDomain: function(el, options = {}) {
    var domains = options.domains.split(',');
    return domains.some(domain => window.location.hostname.indexOf(domain) > -1);
  },

  /**
   * Only show the slot if the URL passed matches the page.
   * @param  {Object} el           The neighbor element.
   * @param  {Object} [options={}] An object of options to check against.
   *                               Accepts one or more hostnames as comma-separated strings
   *                               e.g., vox.com, miami.curbed.com, curbed.com
   * @return {Boolean}             Whether or not the slot should be put there.
   */
  matchUrl: function(el, options = {}) {
    var urls = options.urls.split(',');
    return urls.some(url => window.location.href.indexOf(url.trim()) > -1);
  },

  /**
   * Hide the slot if the URL passed matches the page.
   * @param  {Object} el           The neighbor element.
   * @param  {Object} [options={}] An object of options to check against.
   *                               Accepts one or more hostnames as comma-separated strings
   *                               e.g., vox.com, miami.curbed.com, curbed.com
   * @return {Boolean}             Whether or not the slot should be put there.
   */
  hideIfUrlPresent: function(el, options = {}) {
    var urls = options.urls.split(',');
    return !urls.some(url => window.location.href.indexOf(url.trim()) > -1);
  },

  /**
   * Only show the slot if the permutive segment is present on the page.
   * @param  {Object} el           The neighbor element.
   * @param  {Object} [options={}] An object of options to check against.
   *                               Accepts one or more permutive segments as comma-separated strings
   *
   * @return {Boolean}
   */
  matchPermutiveSegment: function(el, options = {}) {
    var permutiveSegments = options.segments.split(',');
    return permutiveSegments.some(segment => window.concertAds?.variables?.permutive?.includes(segment.trim()));
  },

  /**
   * Only show the slot if the key word on the page is present.
   * @param  {Object} el           The neighbor element.
   * @param  {Object} [options={}] An object of options to check against.
   *                               Accepts one or more keywords as comma-separated strings
   *
   * @return {Boolean}
   */
  hideIfVariablePresent: function(el, options = {}) {
    if (!options.variables || typeof options.variables !== 'object') {
      return true; // If not an object, skip processing and show the slot
    }
    // Iterate over each property in the options
    for (const property in options.variables) {
      const keywordProperty = options.variables[property];
      if (!keywordProperty) continue;

      // Split the keywords by commas, trim spaces, and convert to lowercase for comparison for keywords that are on the page
      const keywordsToHide = keywordProperty.split(',').map(keyword => keyword.trim().toLowerCase());

      // Retrieves the keywords that are on the page
      const pageKeywords = window.concertAds?.variables?.[property];

      // Checking to see if the keyword is a string or an array
      const keywordsOnPage = (typeof pageKeywords === 'string' ? [pageKeywords] : pageKeywords || []).map(keyword =>
        keyword.toLowerCase()
      );

      // Check if the user specified keywords from the yml configuration are on the page
      if (keywordsToHide.some(keyword => keywordsOnPage.includes(keyword))) {
        return false;
      }
    }

    return true;
  },

  /**
   * Only show the slot if the device of a given type(s) is being used.
   * @param  {Object} el          The neighbor element.
   * @param  {String} deviceList  A comma-separated list of valid device types:
   *                              mobile, tablet, desktop
   */
  deviceType: function(el, deviceList) {
    const devices = deviceList.split(',').map(d => d.trim());

    return devices.indexOf(getDeviceType()) > -1;
  },

  /**
   * Only show the slot if a given event has occured.
   *
   */
  loadAfter: function() {
    return true;
  },
};

let floatedSiblingPositions;
let floatedElementPositions;

/**
 * Track sibling floated elements:
 * WeakMap<DOMNode, Array>
 *
 * Note: WeakMap is not available in PhantomJS, and it breaks related tests. This is
 * a "polyfill" in that it does nothing, which is OK, because it never gets
 * executed in PhantomJS.
 */
function createElementTrackers() {
  floatedSiblingPositions = typeof WeakMap === 'undefined' ? {} : new WeakMap();
  floatedElementPositions = typeof WeakMap === 'undefined' ? {} : new WeakMap();
}

createElementTrackers();

/**
 * Fired by consumer of the above filters (SlotBuilder) when a new slot is inserted.
 */
export function resetFilters() {
  createElementTrackers();
}

/**
 * Get the (cached) top/bottom positions for an element.
 * @param {Node} el An element for which to get the top/bottom positions
 * @return {Array<number>,<number}
 */
function getFloatedElementPositions(el) {
  if (floatedElementPositions.has(el)) {
    return floatedElementPositions.get(el);
  }

  let rect = el.getBoundingClientRect();
  let position = [rect.top, rect.bottom];

  floatedElementPositions.set(el, position);

  return position;
}

/**
 * Get an array of arrays [top, bottom] of locations of floated elements in
 * this element's parent container (e.g. siblings).
 *
 * @param {Node} el The node whose parent we want to check.
 * @return {Array<number, number>[]}
 */
function getFloatedSiblingPositions(el) {
  if (floatedSiblingPositions.has(el.parentNode)) {
    return floatedSiblingPositions.get(el.parentNode);
  }

  let positions = [...el.parentNode.children]
    .filter(e => window.getComputedStyle(e).float !== 'none')
    .map(e => getFloatedElementPositions(e));

  floatedSiblingPositions.set(el.parentNode, positions);

  return positions;
}

/**
 * Check if we avoid the element given the specified range
 * @param {Object} option  selector: string
 *                         after: number (defaults to 0)
 *                         before: number (defaults to 0)
 * @param {Object} el  Ad DOM Element to check
 * @returns
 */
function selectorOverlapsEl(option, el) {
  const adRect = el.getBoundingClientRect();
  if (!option.selector) return false;
  const avoidedElements = [...document.querySelectorAll(option.selector)];
  const adClearBox = {
    top: adRect.top - (option.before || 0),
    bottom: adRect.bottom + (option.after || 0),
  };
  for (let i = 0; i < avoidedElements.length; i++) {
    const boxRec = avoidedElements[i].getBoundingClientRect();
    if (boxesOverlapVertically(boxRec, adClearBox)) {
      return true;
    }
  }
  return false;
}

/**
 * Determine if two boxes overlap vertically (does not test horizontal overlap)
 *
 * @param {Object} box1 - first box to test, must have top, bottom properties
 * @param {Object} box2 - second box to test, must have top, bottom properties
 * @return {Boolean} - true if the boxes
 */
export function boxesOverlapVertically(box1, box2) {
  /*
  * There are four ways that ad and boxes can overlap:
  *  Instance 1      Instance 2      Instance 3      Instance 4
  *  ----------        #====#        ----------        #====#
  *  |   AD   |        #====#        |   AD   |      |-#====#-|
  *  | #====# |      |-#====#-|      | #====# |      |-#====#-|
  *  | #====# |      | #====# |      |-#====#-|        #====#
  *  ----------      |   AD   |        #====#
  *                  ----------        #====#
  *
  *  1. Ad surrounds element
  *  2. Top of ad overlaps element
  *  3. Bottom of ad overlaps element
  *  4. Element surrounds ad
  *
  *  This can be boiled down to four checks to see if the top and bottom of either box
  *  appear within the other box

  */
  return (
    (box1.top >= box2.top && box1.top <= box2.bottom) ||
    (box2.top >= box1.top && box2.top <= box1.bottom) ||
    (box1.bottom >= box2.top && box1.bottom <= box2.bottom) ||
    (box2.bottom >= box1.top && box2.bottom <= box1.bottom)
  );
}
