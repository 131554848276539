import { TRANSITION_END } from '../effects';
import Logger from '../logger';
import SlotWrapper from '../slot_wrapper';

// Class name for our dynamic slots.
const DYNAMIC_AD_CLASS = 'dynamic-js-slot';
const COLLAPSE_SELECTOR = 'm-ad__collapsed';

/**
 * A SlotWrapper is responsible for most, if not all, DOM manipulations for slot
 * objects. The basic lifecycle of a SlotWrapper is as follows:
 *
 * - SlotWrapper is created in SlotBuilder
 * - Creates wrapperElement and slotElement DOM elements
 * - Inserts elements into DOM using insertNextTo() method
 * - On render, slot calls the rendered() callback on SlotWrapper, which sets
 * the height of the slotElement wrapper based on what happened. If the slot
 * is blank, the wrapper collapses elegantly. If it is filled, it sets classes
 * and transitions to the rendered height. It sets it to height: auto; after
 * the transition is complete, in case the ad is iframe-busting.
 * - On view (impressionViewable), if the slot is refreshEligible, it inherits
 * the height of the slot's first child element. This height persists through a
 * refresh event so the reader doesn't see jank.
 */
export default class GamAdSlotWrapper extends SlotWrapper {
  /**
   * Slot Wrapper accepts an object with:
   * @param  {Slot}     slot       Instance of Slot
   * @param  {object}   config     Config
   * @param  {boolean}  existing   Whether we're creating from an existing DOM el
   */
  constructor({ slot, config, existing }) {
    super({ slot, config, existing });
  }

  /**
   * Callback run when the slot has rendered inside the wrapper
   * @param  {Event} evt  Event sent from DFP
   * @return {undefined}
   */
  rendered(evt) {
    // Dispatch an event to the iframe to let it know we have rendered
    dispatchRenderedEventToIframe.call(this, evt);

    const isEmpty = isEmptyOrBlank.call(this, evt);

    // We don't make any changes that might affect a slot's
    // height after it has been refreshed once to prevent jank
    if (!this.slot.hasBeenRefreshed()) {
      addRenderClassesToSlotElement.call(this, isEmpty);
      addRenderClassesToSlotWrapper.call(this, isEmpty);

      // Run the slot collapsed callback
      if (isEmpty && isSlotWrapperBottomBelowViewport.call(this)) {
        this.slot.collapsed();
      }

      // Resize the slot, whether collapsed or full
      resize.call(this, isEmpty, evt);
    }

    if (isEmpty) {
      this.slotElement.classList.remove('dfp_ad--is-filled');
    }

    if (evt.isEmpty) {
      Logger.log(`${this.slot.name} returned empty from DFP.`);
    }

    if (isBlank.call(this, evt)) {
      Logger.log(`${this.slot.name} collapsed via DFP script.`);
    }
  }

  /**
   * Get the slot iframe's height.
   * @returns {array} [width<int>, height<int>]
   */
  getIframeSize() {
    const iframe = this.slotElement.querySelector('iframe');

    if (!iframe) return false;

    const { width, height } = iframe;
    return [parseInt(width), parseInt(height)];
  }

  /**
   * Get the name of the event that will be emitted when the slot
   * has been rendered.
   * @return {string}
   */
  getRenderedEventName() {
    return this.slot.name + '_rendered';
  }

  /**
   * Callback run when a slot is being refreshed. Measures the height of the
   * slot creative, and persists it for future refreshes.
   * @return {undefined}
   */
  preserveHeight() {
    // Only preserve a height if this is the first time a slot is refreshed
    if (!this.slot.hasBeenRefreshed()) {
      const height = this.getIframeSize()[1] + 'px';
      Logger.log(`Persisting ${this.slot.name} height as ${height} for all future refreshes.`);
      this.slotElement.style.height = height;
    }
  }

  /**
   * Set the height of the slotElement to auto.
   * This method is only called internally, but it is needed for tests.
   */
  setHeightToAuto() {
    this.slotElement.style.height = 'auto';
  }

  /**
   * Create a wrapper element and return it
   * @return {Node}  Wrapper div
   */
  createWrapperElement() {
    return super.createWrapperElement({
      classes: ['m-ad', 'm-ad__dynamic_ad_unit'],
      classNamePrefix: 'm-ad__',
    });
  }

  insertSlotDisplayElement() {
    return super.insertSlotDisplayElement({
      classes: ['m-ad', 'm-ad__dynamic_ad_unit'],
      classNamePrefix: 'm-ad__',
    });
  }

  /**
   * Create a slot element and return the Node
   * @return {Node}
   */
  createSlotElement() {
    return super.createSlotElement({
      classes: [DYNAMIC_AD_CLASS],
      classNamePrefix: 'dfp_ad--',
    });
  }
}

/**
 * Resize the slot.
 * @param  {Boolean} isEmpty Was the slot empty?
 * @param  {object}  evt     Event
 * @return {undefined}
 */
function resize(isEmpty, evt) {
  if (isEmpty) {
    // Set the height and width to 0 so it can be animated with CSS
    this.slotElement.style.width = 0;
    this.slotElement.style.height = 0;

    return;
  }

  addHeightFromSlot.call(this, evt);
}

/**
 * Dispatch an event to the IFrame so it can do things when rendered.
 * @param  {Event} evt
 * @return {undefined}
 */
function dispatchRenderedEventToIframe(evt) {
  var iframe = this.slotElement.querySelector('iframe');
  if (iframe && !evt.isEmpty && iframe.height !== 0) {
    var renderEvent;
    if (typeof Event == 'function') {
      renderEvent = new Event(this.getRenderedEventName(), { bubbles: true });
    } else {
      renderEvent = document.createEvent('Event');
      renderEvent.initEvent(this.getRenderedEventName(), true, false);
    }
    this.slotElement.dispatchEvent(renderEvent);
  }
}

/**
 * Is the slot intentionally blank?
 * @param  {Event}  evt
 * @return {Boolean}
 */
function isBlank() {
  // If the script was blanked using a DFP script
  var scriptBlanked = this.slotElement.querySelector('[style*="display"]');
  if (scriptBlanked && scriptBlanked.style.display === 'none') {
    return true;
  }

  return false;
}

/**
 * Is the slot either empty or intentionally blank?
 * @param  {Event}  evt
 * @return {Boolean}
 */
function isEmptyOrBlank(evt) {
  return evt.isEmpty || isBlank.call(this);
}

/**
 * Add render classes to the slot element based on whether the slot is empty
 * @param {Event} evt
 */
function addRenderClassesToSlotElement(isEmpty) {
  var add = isEmpty ? 'dfp_ad--is-empty' : 'dfp_ad--is-filled';
  var rm = isEmpty ? 'dfp_ad--is-filled' : 'dfp_ad--is-empty';
  this.slotElement.classList.add('dfp_ad--rendered', add);
  this.slotElement.classList.remove(rm);

  // The above class changes trigger a CSS transition. We want to do some cleanup
  // after the transition completes, or 1s max, if the slot is collapsing.
  if (isEmpty) {
    this.slotElement.addEventListener(TRANSITION_END, completeCollapseTransition.bind(this));
    setTimeout(completeCollapseTransition.bind(this), 1000);
  }
}

/**
 * Add render classes to the slot wrapper based on whether the slot is empty
 * @param {Event} evt
 */
function addRenderClassesToSlotWrapper(isEmpty) {
  var add = isEmpty ? 'dfp_ad-wrapper--is-empty' : 'dfp_ad-wrapper--is-filled';
  var rm = isEmpty ? 'dfp_ad-wrapper--is-filled' : 'dfp_ad-wrapper--is-empty';
  this.wrapperElement.classList.add(add);
  this.wrapperElement.classList.remove(rm);
}

/**
 * Complete the collapsing transition
 * @return {undefined}
 */
function completeCollapseTransition() {
  this.wrapperElement.classList.add(COLLAPSE_SELECTOR);
  this.slotElement.removeEventListener(TRANSITION_END, completeCollapseTransition);
}

/**
 * Add the actual returned height to a slot element after it is rendered. This
 * is because we request multiple sizes for a single slot, and we want to change
 * the height of a slot if one was set. If the slot has been refreshed, we don't
 * want to override the current set height, because that is the source of truth
 * for the given slot height instead of what comes back from DFP.
 * @param {Event} evt
 */
function addHeightFromSlot(evt) {
  if (!evt.size || this.slot.hasBeenRefreshed()) {
    return;
  }

  if (this.config.shouldSkipTransition) {
    this.setHeightToAuto();
  } else {
    this.slotElement.style.height = evt.size[1] + 'px';

    // Set height to auto after 1s
    setTimeout(() => {
      this.setHeightToAuto();
    }, 1000);
  }
}

/**
 *  Improving slot logic.
 *  If the ad is below the viewport and the GAM request is empty, the ad slot should be collapsed.
 *  @return {boolean}
 */
function isSlotWrapperBottomBelowViewport() {
  let viewportHeight = Math.max(window.innerHeight, 1);
  return (
    this.wrapperElement.getBoundingClientRect().top > viewportHeight ||
    this.wrapperElement.getBoundingClientRect().bottom > viewportHeight
  );
}
