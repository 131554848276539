import Logger from './logger';

// Default to true for unsupported browsers
let _isPageVisible = true;
let whenVisibleQueue = [];

if (document && document.hidden !== undefined) {
  Logger.log('Using Page Visibility API');
  _isPageVisible = !document.hidden;

  document.addEventListener('visibilitychange', () => {
    _isPageVisible = !document.hidden;

    Logger.log(`Page visibility changed: ${_isPageVisible}`);
    if (_isPageVisible) {
      whenVisibleQueue.forEach(someFunction => someFunction.call());
      whenVisibleQueue = [];
    }
  });
} else {
  Logger.log('Not using Page Visbility API');
}

/**
 * A convenience function to defer a functions execution until the page is visible
 * Makes use of the Page Visibility API if available, if not it will run immediately.
 *  https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
 *
 * @param {Function} someFunction the action to run when the page is visible
 * @param {Object} runImmediately When runImmediately is set to true, will always
 *                                run the function regardless of the page visiblity signals
 */
export const deferUntilPageVisible = (someFunction = () => {}, { runImmediately = false } = {}) => {
  if (_isPageVisible || runImmediately) {
    someFunction.call();
  } else {
    Logger.log('Defering action until page is visible');
    whenVisibleQueue.push(someFunction);
  }
};

/**
 * Check if the page is visbile
 *
 * @returns {Boolean} true if visible, false if hidden
 */
export const isPageVisible = () => _isPageVisible;
