import Logger from '../logger';
import Slot from '../slot';
import ScriptLoader from '../script_loader';

export default class XcoSlot extends Slot {
  constructor(app, data) {
    super(app, data);
  }

  /**
   * Show an ad in this slot
   * @return {undefined}
   */
  render() {
    if (!super.render()) return;

    Logger.log(`Loading ${this.name}`);

    this.setUpXco();
    this.loadScript();
  }

  /**
   * Determine whether this slot can be displayed on the page
   * @return {boolean}
   */
  canBeDisplayed() {
    return Boolean(super.canBeDisplayed() && this.app.settings.xcoUrl && this.data.playerId);
  }

  /**
   * Add required attribute to Xco wrapper element
   * @return {undefined}
   */
  setUpXco() {
    this.xcoElement = document.createElement('div');
    this.xcoElement.id = this.data.playerId;
    this.xcoElement.style.maxWidth = '600px';
    this.xcoElement.style.margin = '0 auto';
    this.element.style.paddingBottom = '22px';
    this.element.appendChild(this.xcoElement);
  }

  /**
   * Load Xco Script
   * @return {undefined}
   */
  loadScript() {
    const dependency = {
      url: this.app.settings.xcoUrl,
      timeout: 2000,
      parentElement: this.element,
      customAttributes: {
        class: 'playbuzz-stream',
      },
    };

    new ScriptLoader()
      .load(dependency)
      .then(url => {
        Logger.log(`${this.name} script ${url} has loaded successfully`);
        this.rendered();
        this.markAsDisplayed();
      })
      .catch(error => {
        Logger.log(`Error loading ${this.name} script: ${error}`);
        Logger.log(`${this.name} has been collapsed.`);
        this.wrapper.collapseSlotWrapper();
        this.collapsed();
      });
  }

  static isSlotTypeFor(config) {
    return config.type === 'xco';
  }
}
