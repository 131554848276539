export default class ScriptLoader {
  /**
   * Framework for loading external scripts
   * @param { url, callback, defer, timeout, parentElement, customAttributes }
   *
   */
  load({ url, callback, defer, timeout = 2500, parentElement, customAttributes = {} }) {
    const scriptElement = document.querySelector(`script[src="${url}"]`);

    return new Promise((resolve, reject) => {
      try {
        if (!scriptElement) {
          const element = parentElement || document.querySelector('head');
          const script = document.createElement('script');

          const defaultAttributes = {
            type: 'text/javascript',
            src: url,
          };

          const attributes = { ...defaultAttributes, ...customAttributes };

          if (defer) {
            attributes.defer = true;
          } else {
            attributes.async = true;
          }

          Object.keys(attributes).forEach(attribute => script.setAttribute(attribute, attributes[attribute]));

          // Set a timeout just in case the script never loads.
          const timer = setTimeout(() => {
            reject(`The script ${url} took longer than ${timeout} to load.`);
          }, timeout);

          script.onload = () => {
            // If a callback was provided, fire when the script loads
            if (callback) callback();

            // Cancel the rejection timer
            clearTimeout(timer);

            resolve(url);
          };

          element.appendChild(script);
        } else {
          if (callback) callback();
          resolve();
        }
      } catch (e) {
        reject(e);
      }
    });
  }
}
