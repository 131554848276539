/**
 * Google Tag Manager's dataLayer is where they keep all the fun analytics
 * information.
 * @type {object}
 */
var localDataLayer = [];

function syncDataLayer() {
  if (window.dataLayer) {
    while (localDataLayer.length) {
      window.dataLayer.push(localDataLayer.unshift());
    }
  } else {
    setTimeout(syncDataLayer, 300);
  }
}

function pushToDataLayer(message) {
  if (window.dataLayer) {
    window.dataLayer.push(message);
  } else {
    localDataLayer.push(message);
    setTimeout(syncDataLayer, 300);
  }
}

export default pushToDataLayer;
