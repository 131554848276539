import Logger from '../lib/logger';
import PluginBase from './base';

export default class BlockthroughSetup extends PluginBase {
  onSettingsLoaded() {
    Logger.log('Installing plugin: BlockthroughSetup');
    const btScriptUrl = this.app.settings.blockthroughScriptURL;
    if (btScriptUrl && !document.querySelector(`script[src="${btScriptUrl}"]`)) {
      const head = document.querySelector('head');
      const script = document.createElement('script');

      script.setAttribute('type', 'text/javascript');
      script.setAttribute('src', btScriptUrl);
      script.async = true;
      head.appendChild(script);
      Logger.log(`Appending Blockthrough script to head`);
    }
    if (process.env.NODE_ENV === 'test') {
      window.dispatchEvent(new Event('blockthroughPluginReady'));
    }
  }
}
