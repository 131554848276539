import Events, { EventTypes } from './events';
import stickybits from 'stickybits';
import Constants from './constants';

const PREVIEW_AD_CLASS = 'preview-ad-slot';

/**
 * A SlotWrapper is responsible for most, if not all, DOM manipulations for slot
 * objects. The basic lifecycle of a SlotWrapper is as follows:
 *
 * - SlotWrapper is created in SlotBuilder
 * - Creates wrapperElement and slotElement DOM elements
 * - Inserts elements into DOM using insertNextTo() method
 */
export default class SlotWrapper {
  /**
   * Slot Wrapper accepts an object with:
   * @param  {Slot}     slot       Instance of Slot
   * @param  {object}   config     Config
   * @param  {boolean}  existing   Whether we're creating from an existing DOM el
   */
  constructor({ slot, config, existing }) {
    this.slot = slot;

    if (!existing) {
      this.config = config || {};

      this.wrapperElement = this.createWrapperElement();
      this.slotElement = this.createSlotElement();
      this.slot.element = this.slotElement;
      this.slot.wrapper = this;

      // Append the slot inside the wrapper
      this.wrapperElement.appendChild(this.slotElement);

      if (getSlotDisplay(this.config, this.slot.app.settings)) {
        this.insertSlotDisplayElement();
      }

      Events.emit(EventTypes.slotWrapperCreated, { name: this.slot.name, config: this.config });
    }

    if (this.slot.element) {
      this.slot.element.__slot__ = this.slot;
    }
  }

  /**
   * Insert this slotWrapper into the DOM
   * @param  {Node} neighbor   DOM node
   * @return {self}
   */
  insertNextTo(neighbor) {
    // If we want to add in the slot after or inside the selector instead of before, we
    // have that option.
    if (this.config.insertion && this.config.insertion === 'after') {
      // Here we check if we are the lastChild. In english, if you are the
      // last child of your own parent - then you are the end of the line.
      if (neighbor.parentNode.lastChild.isEqualNode(neighbor)) {
        // Since we are the end, append.
        neighbor.parentNode.appendChild(this.element);
      } else {
        neighbor.parentNode.insertBefore(this.element, neighbor.nextSibling);
      }
    } else if (this.config.insertion && this.config.insertion === 'inside') {
      // Insert inside
      neighbor.appendChild(this.element);
    } else {
      // Standard insert— before.
      neighbor.parentNode.insertBefore(this.element, neighbor);
    }

    this.slot.inserted();

    if (this.config.sticky && this.config.sticky.enabled) this.applyStickyPolyfill(this.config.sticky.offset);

    return this;
  }

  /**
   * Remove this slot wrapper from the DOM.
   */
  destroy() {
    if (this.stickyPolyfill) this.stickyPolyfill.cleanup();
    this.wrapperElement.remove();
  }

  /**
   * Getter method for the wrapperElement
   * @return {Node}
   */
  get element() {
    return this.wrapperElement;
  }

  /**
   * Applies the sticky polyfill to the wrapper. Only public for the purpose
   * of tests.
   */
  applyStickyPolyfill(offset) {
    this.stickyPolyfill = stickybits(this.wrapperElement, { stickyBitStickyOffset: offset });
  }

  collapseSlotWrapper() {
    this.wrapperElement.style.display = 'none';
  }

  /**
   * Create a wrapper element and return it
   * @return {Node}  Wrapper div
   */
  createWrapperElement(opts) {
    let wrapper = document.createElement('div');
    let classes = opts?.classes || [];
    const classNamePrefix = opts?.classNamePrefix || '';

    if (this.config.slotClassName) classes.push(this.config.slotClassName);

    if (this.config.classNames && Array.isArray(this.config.classNames)) {
      this.config.classNames.forEach(className => classes.push(className));
    }

    if (typeof this.config.inlineStyles === 'object') {
      Object.keys(this.config.inlineStyles).forEach(styleKey => {
        wrapper.style.cssText += `${styleKey}: ${this.config.inlineStyles[styleKey]};`;
      });
    }

    if (this.config.sticky?.enabled) {
      wrapper.style.cssText += `
        position: -webkit-sticky;
        position: sticky;
        top: ${this.config.sticky.offset}px;
      `;

      classes.push(`${classNamePrefix}sticky`);
    }

    if (this.config.reveal?.enabled) {
      wrapper.style.cssText += `
        z-index: ${this.config.reveal.zIndex};
        top: ${this.config.reveal.offset}px;
      `;

      wrapper.setAttribute('data-concert-ads-target', this.config.reveal.observationTarget);

      classes.push(`${classNamePrefix}reveal`);
    }

    if (typeof this.config.athenaMaxSize === 'number') {
      wrapper.setAttribute('data-concert-ads-athena-max-size', this.config.athenaMaxSize);
    }

    if (this.config.name) {
      if (!this.config.slotClassName) classes.push(classNamePrefix + this.config.name);

      wrapper.setAttribute('data-concert-ads-name', this.config.name);
    }

    wrapper.className = classes.join(' ');

    // Adding data-uri for NYMag sites in preview mode to fix component error
    if (this.slot.app.settings.isPreview && this.slot.app.settings.dataUri) {
      wrapper.dataset.uri = this.slot.app.settings.dataUri;
    }

    return wrapper;
  }

  /**
   * Create a slot display element and insert into the wrapper element
   */
  insertSlotDisplayElement(opts) {
    const { className, link, message, insertion } = getSlotDisplay(this.config, this.slot.app.settings);

    const classNamePrefix = opts?.classNamePrefix || '';

    if (className) this.wrapperElement.classList.add(classNamePrefix + className);

    if (className === 'sponsorship-message' && link && message) {
      let linkEl = document.createElement('a');
      linkEl.href = link;
      linkEl.title = message;

      let messageEl = document.createElement('div');
      messageEl.appendChild(document.createTextNode(message));
      messageEl.classList.add('message');

      linkEl.appendChild(messageEl);

      if (insertion === 'after') {
        this.wrapperElement.appendChild(linkEl);
      } else {
        this.wrapperElement.insertBefore(linkEl, this.slotElement);
      }
    }
  }

  /**
   * Create a slot element and return the Node
   * @return {Node}
   */
  createSlotElement(opts) {
    let adSlot = document.createElement('div');
    let classes = opts?.classes || [];
    const classNamePrefix = opts?.classNamePrefix || '';

    // if app settings have previewHoldSizeEnabled, then we want the slotWrapper to have the previewSize set
    if (
      this.slot.app.settings.previewHoldSizeEnabled &&
      this.slot.app.settings.isPreview &&
      this.slot.previewHoldSize
    ) {
      classes.push(PREVIEW_AD_CLASS);
      adSlot.style.width = this.slot.previewHoldSize[0] + 'px';
      adSlot.style.height = this.slot.previewHoldSize[1] + 'px';
    }

    adSlot.id = this.slot.id;

    // Don't want to set hold size if this is a preview
    if (this.slot.getHoldSize() && !Constants.CONCERT_PREVIEW) {
      const holdWidthOrViewport = Math.min(this.slot.getHoldSize()[0], document.documentElement.clientWidth);
      classes.push(`${classNamePrefix}held-area`);
      adSlot.style.minWidth = holdWidthOrViewport + 'px';
      adSlot.style.height = this.slot.getHoldSize()[1] + 'px';
    }

    adSlot.className = classes.join(' ');

    return adSlot;
  }

  /**
   * Create a new SlotWrapper instance from an existing slot on the page. This is
   * used mainly for legacy parts of Chorus.
   * @param  {Slot} slot        Slot instance
   * @return {SlotWrapper}      SlotWrapper instance
   */
  static newFromExistingSlot(slot) {
    let wrapper = slot.getSlotWrapper({ slot, existing: true });

    wrapper.slotElement = document.getElementById(slot.id);

    if (!wrapper.slotElement) {
      return;
    }

    slot.element = wrapper.slotElement;
    slot.element.__slot__ = slot;
    wrapper.wrapperElement = wrapper.slotElement.parentNode;

    return wrapper;
  }
}

/**
 * Get slotDisplay configs, which are applied on the settings-level or slot-level
 * and can be disabled for individual slots.
 * @param  {Slot} config {object} Slot config
 * @param  {Slot} settings {object} Network Settings
 * @return {object}
 */
function getSlotDisplay(config, settings) {
  return config.slotDisplay === false ? config.slotDisplay : config.slotDisplay || settings.slotDisplay;
}
