import { fetchJson } from '../lib/fetch_json';
import Logger from '../lib/logger';
import { getEntryUrl } from './context';

export default {
  /**
   * Fetch targeting key/values from the Ad Viewability API.
   *
   * @param  {{ endpoint: string }} options
   * @return {Promise<{[key: string]: string|string[]}>} Returns an object with targeting key/values.
   */
  loadViewabilityDeciles(options = {}) {
    return new Promise(resolve => {
      const { endpoint, targetingKey } = options;

      if (!endpoint || !targetingKey) {
        Logger.error(`An endpoint and targeting key must be passed to the loadViewabilityDeciles classifier`);
        return resolve(false);
      }

      const url = getEntryUrl();

      const payload = {
        url,
      };

      fetchJson(endpoint, { method: 'POST', data: payload, contentType: 'application/json' })
        .then(result => {
          let deviceType = this.variables.device_type;
          let deciles = [];

          if (result.data) {
            if (
              result.data.domain_avg &&
              !result.data.desktop_avg &&
              !result.data.mobile_avg &&
              !result.data.tablet_avg
            ) {
              return resolve({ [targetingKey]: getViewabilityDeciles(result.data.domain_avg) });
            }
          }

          switch (deviceType) {
            case 'desktop':
              deciles.push(getViewabilityDeciles(result.data.desktop_avg));
              break;

            case 'mobile':
              deciles.push(getViewabilityDeciles(result.data.mobile_avg));
              break;

            case 'tablet':
              deciles.push(getViewabilityDeciles(result.data.tablet_avg));
              break;

            default:
              Logger.error(`'device' must be one of 'desktop', 'tablet', 'mobile'... was ${deviceType}`);
              resolve();
          }

          resolve({ [targetingKey]: deciles });
        })
        .catch(e => {
          Logger.error(`loadViewabilityDeciles failed to fetch targeting from ${endpoint}`, {
            error: e.message,
          });
          resolve(false);
        });
    });
  },
};

function getViewabilityDeciles(viewability) {
  if (!viewability) return [];
  const maxDecile = Math.floor(10 * viewability) * 10;
  const deciles = [];
  for (let x = maxDecile; x > 0; x -= 10) {
    deciles.push(x);
  }
  return deciles;
}
