import Logger from '../lib/logger';
import PluginBase from './base';
import * as prebidFunctions from '../lib/prebid_helper';
import ScriptLoader from '../lib/script_loader';

export const IX_IDENTITY_LIBRARY_URL = 'https://js-sec.indexww.com/ht/p/183789-71940066017360.js';

export default class IXIdentityLibrary extends PluginBase {
  onBidManagerCreated() {
    Logger.log('Installing plugin: IXIdentityLibrary');

    if (!this.app.settings.prebid.biddingEnabled) return;

    if (this.app.settings.ixLibraryEnabled) {
      this.loadIXLibraryScript();
    }
  }

  /**
   * Making sure we load the IX Identity Library script before bids are fetched
   * We will update the prebid user sync once the script is loaded
   */
  loadIXLibraryScript() {
    const ixIdentityDependency = {
      url: IX_IDENTITY_LIBRARY_URL,
      timeout: 2500,
      callback: this.updatePrebidUserSync,
    };

    this.app.bidManager.beforeFirstBid(() => {
      return new Promise((resolve, reject) => {
        new ScriptLoader()
          .load(ixIdentityDependency)
          .then(url => {
            Logger.log(`Script ${url} has loaded successfully`);
            resolve();
          })
          .catch(error => {
            Logger.log(`Error loading external script: ${error}`);
            reject();
          });
      });
    });
  }

  /**
   * Getting IX Identity info and updating Prebid userSync config
   */
  updatePrebidUserSync() {
    if (!prebidFunctions.prebidScriptLoaded()) {
      return;
    }

    let userSync = prebidFunctions.getUserSync();
    const rtiData = window.headertag?.getIdentityInfo?.();

    if (rtiData?.AdserverOrgIp?.data?.uids) {
      const userId = rtiData.AdserverOrgIp.data.uids.filter(uid => {
        return uid.ext.rtiPartner === 'TDID';
      });

      const tdidValue = userId[0].id ? userId[0].id : null;

      if (!userSync || !tdidValue) return;

      const updatedUserSync = {
        userIds: [
          {
            name: 'unifiedId',
            value: `{'tdid': '${tdidValue}'}`,
          },
        ],
      };

      userSync = Object.assign({}, userSync, updatedUserSync);

      prebidFunctions.updateUserSync(userSync);
    }
  }
}
