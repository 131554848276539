import Logger from '../lib/logger';
import PluginBase from './base';
import ScriptLoader from '../lib/script_loader';

export default class ATS extends PluginBase {
  onBidManagerCreated() {
    if (!this.isAtsEligible()) return;
    Logger.log('Installing plugin: Authenticated Traffic Solution');
    this.loadAtsScript();
  }

  isAtsEligible() {
    return (
      this.app.settings.prebid.ats?.enabled &&
      this.app.settings.prebid.ats?.scriptUrl &&
      this.app.settings.prebid.ats?.placementId
    );
  }

  /**
   * Load the ATS script
   */
  loadAtsScript() {
    const atsDependency = {
      url: this.app.settings.prebid.ats?.scriptUrl,
    };

    this.app.bidManager.beforeFirstBid(() => {
      return new Promise((resolve, reject) => {
        new ScriptLoader()
          .load(atsDependency)
          .then(url => {
            Logger.log(`Script ${url} has loaded successfully`);
            this.atsCallback();
            resolve();
          })
          .catch(error => {
            Logger.log(`Error loading external script: ${error}`);
            reject();
          });
      });
    });
  }

  /**
   * Run the ATS start method
   */
  atsCallback() {
    Logger.log(`Calling ATS start method.`);
    window.ats.start({
      placementID: this.app.settings.prebid.ats?.placementId,
      storageType: 'localStorage',
      detectionType: 'scrapeAndUrl',
      urlParameter: 'email',
      cssSelectors: ['input[type=text]', 'input[type=email]'],
      logging: 'error',
    });
  }
}
