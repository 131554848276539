import Plugins from '../plugins';
import Logger from './logger';

export default class PluginLoader {
  constructor({ app }) {
    this.app = app;

    Plugins.forEach(plug => {
      Logger.log(`Using plugin ${plug}`);
      new plug({ app: this.app });
    });
  }
}
