import Logger from './logger';
import { performanceMark } from './utils';
import Events, { EventTypes } from '../lib/events';

let firstAdRendered = false;

export default class Ad {
  /**
   * Create a new ad instances
   * @param  {Slot} slot   Slot instance
   * @return {Ad}   self
   */
  constructor(slot) {
    this.slot = slot;

    return this;
  }

  show() {
    // Mark that we have sent this for display.
    this.slot.markAsDisplayed();

    var logMessage = '';

    if (this.slot.hasBeenRefreshed()) {
      // Add a key/value to know this is a refresh.
      this.slot.setTargeting('refresh', [true]);
      logMessage += 'Refreshing';
      this.slot.resetRefreshQueued();
    } else {
      logMessage += 'Displaying';
    }

    googletag.cmd.push(() => {
      // Wrapping in DV Tag Ready Method
      if (typeof window.onDvtagReady === 'function') {
        window.onDvtagReady(() => {
          googletag.pubads().refresh([this.slot.slot]);
        });
      } else {
        googletag.pubads().refresh([this.slot.slot]);
      }

      Logger.log(`${logMessage} ad slot: ${this.slot.name}`);
    });
  }

  /**
   * Callback method for when an ad is rendered.
   * Creates and dispatches an event if an ad is the first one to render on page
   * @return {undefined}
   */
  rendered() {
    // Only the first ad will mark and dispatch this event
    if (!firstAdRendered) {
      var eventName = 'first_ad_rendered';
      performanceMark(eventName);
      firstAdRendered = true;

      var event = new Event(eventName, { bubbles: true });
      document.body.dispatchEvent(event);
      Events.emit(EventTypes.firstAdRendered);
    }
  }
}
