/* global dvtag PQ */

import Logger from '../lib/logger';
import PluginBase from './base';

export default class DoubleVerifyTargeting extends PluginBase {
  onSettingsLoaded() {
    Logger.log('Installing plugin: Double Verify Targeting');

    if (!this.isDoubleVerifyEligible()) return;

    this.setUpDVTagReady();
  }

  /**
   * Check if we want to load Double Verify Targeting
   */
  isDoubleVerifyEligible() {
    return this.app.settings.doubleVerify?.enabled;
  }

  /**
   * Set up Double Verify DV Tag Ready Method
   */
  setUpDVTagReady() {
    window.onDvtagReady =
      window.onDvtagReady ||
      function(callback, timeout = 750) {
        window.dvtag = window.dvtag || { cmd: [] };
        const opt = { callback, timeout, timestamp: new Date().getTime() };
        dvtag.cmd.push(function() {
          dvtag.queueAdRequest(opt);
        });

        setTimeout(function() {
          const cb = opt.callback;
          opt.callback = null;
          if (cb) cb();
        }, timeout);

        window.PQ = window.PQ || { cmd: [] };
        PQ.cmd.push(function() {
          PQ.loadSignals();
        });
      };
  }
}
