import Logger from '../lib/logger';
import PluginBase from './base';
import store from '../lib/store';
import Events, { EventTypes } from '../lib/events';
import { getClosestBucketForValue } from '../buckets';
import settings from '../lib/settings';

const SAMPLE_RATE_IN_MS = 250;

// We're OK caching this value, since window heights will not often change
const VIEWPORT_HEIGHT = window.innerHeight;

export default class ScrollVelocity extends PluginBase {
  onInstall() {
    Logger.log('Installing plugin: ScrollVelocity');

    store.set('scroll-position', window.scrollY);

    setInterval(() => this.measure(), SAMPLE_RATE_IN_MS);
  }

  // Apply the current scroll velocity as a targeting key/value when an ad
  // is observed in viewport.
  onSlotObserved(
    eventName,
    {
      payload: { slotName },
    }
  ) {
    const slot = this.app.slots.get(slotName);
    const scrollVelocity = store.get('scroll-velocity');

    if (!slot || (typeof slot.shouldTrackScrollVelocity === 'function' && !slot.shouldTrackScrollVelocity())) return;

    slot.setTargeting('c_sv', bucketedScrollVelocity(scrollVelocity));
  }

  measure() {
    const currentPosition = window.scrollY;
    const previousPosition = store.get('scroll-position');
    const previousVelocity = store.get('scroll-velocity');
    const scrollVelocity = calculateScollVelocity(VIEWPORT_HEIGHT, previousPosition, currentPosition);

    if (scrollVelocityExceedsThreshold(previousVelocity) && !scrollVelocityExceedsThreshold(scrollVelocity)) {
      Events.emit(EventTypes.scrollVelocityBelowThreshold);
    }

    store.set('scroll-velocity', scrollVelocity);
    store.set('scroll-position', currentPosition);
    store.append('scroll-velocity-timeseries', scrollVelocity);
  }
}

/**
 * Calculate the scroll velocity in viewports per second.
 * Takes SAMPLE_RATE_IN_MS into account.
 *
 * @param {number} viewportHeight   The height of the viewport
 * @param {number} previousPosition The previous position, in pixels
 * @param {number} currentPosition  The current position, in pixels
 */
export function calculateScollVelocity(viewportHeight, previousPosition, currentPosition) {
  const delta = currentPosition - previousPosition;
  const deltaScaled = delta / viewportHeight;

  return (deltaScaled * (1000 / SAMPLE_RATE_IN_MS)).toFixed(1);
}

export function bucketedScrollVelocity(scrollVelocity) {
  const precision = 0.5;
  const value = parseFloat(scrollVelocity);

  return getClosestBucketForValue({
    value,
    precision,
    lowerBounds: -4,
    upperBounds: 4,
  });
}

/**
 * Determine whether a given scroll velocity exceeds the defined threshold.
 * If none provided, the current scroll velocity is used.
 *
 * @param {number?} scrollVelocity Scroll velocity to check (optional)
 */
export function scrollVelocityExceedsThreshold(scrollVelocity) {
  const scrollVelocityThreshold = Number(settings.get('scrollVelocityThreshold'));
  scrollVelocity = scrollVelocity || Number(store.get('scroll-velocity'));

  return scrollVelocityThreshold && scrollVelocity && Math.abs(scrollVelocity) > scrollVelocityThreshold;
}
