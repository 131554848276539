import Logger from './logger';

/**
 * This file is managed as a static asset in AWS:
 * https://s3.console.aws.amazon.com/s3/buckets/apps.voxmedia.com?prefix=ad-block-detect/&region=us-east-1
 * This should be kept as a publicly readable file
 */
const blockerTestScriptURL = 'https://apps.voxmedia.com/ad-block-detect/test_ads_prebid_blocker.js';

/**
 * Detects whether we are in test environment.
 * @returns {boolean}
 */
function isTestEnvironment() {
  return process.env.NODE_ENV === 'test';
}

/**
 * Detects whether we have Ad Block set to true in test environment
 * @returns {boolean}
 */
function isAdBlockerEnabledForTest() {
  return process.env.ADS_BLOCKED === 'true';
}

/**
 * Returns true if adblock detection has been installed and an ad blocker is installed
 * @returns {boolean}
 */
function isAdBlockerEnabled() {
  if (!document.body) return false;
  if (isTestEnvironment()) {
    return isAdBlockerEnabledForTest();
  }
  if (window.isAdBlockerActive == undefined) {
    Logger.log('isAdBlockerEnabled called before installAdBlockerDetection has run');
    return false;
  }
  // window.isAdBlockerActive is set when the installAdBlockerDetection() is run, assiged to the window to help debug.
  // window.concertAdBlockDetectorLoaded is set by the file at https://cdn.concert.io/lib/concert-ads//v2-latest/test_ads_prebid_blocker.js
  return window.isAdBlockerActive || !window.concertAdBlockDetectorLoaded;
}

/**
 * Fetch the ad blocker status, and report it directly to the datalayer (GTM).
 */
export function installAdBlockerDetection() {
  return new Promise(resolve => {
    if (isTestEnvironment() || document.querySelector(`script[src="${blockerTestScriptURL}"]`)) {
      resolve(isAdBlockerEnabled());
    } else {
      window.isAdBlockerActive = false; // assigning to the window to help debug.

      // the way that ad blockers work is by blocking files that look "ad-like" from running
      // what we'll do here is set up a file that is basically bait for ad blockers
      // and if it fails to load, we'll make an assuption that an adblocker is in play
      const head = document.getElementsByTagName('head')[0];
      const script = document.createElement('script');

      script.async = true;
      script.src = blockerTestScriptURL;

      script.onload = () => {
        resolve(isAdBlockerEnabled());
      };

      script.onerror = () => {
        // this will be triggered in the event of an adblocker prevents our bait from loading
        window.isAdBlockerActive = true; // assigning to the window to help debug.
        resolve(isAdBlockerEnabled());
      };

      head.append(script);
    }
  });
}
