import Logger from './logger';
import ObserverResize from './observer_resize';

export default class NodeResize {
  /**
   * Insert slots or modify elements based on node resize.
   */
  constructor(app) {
    this.app = app;
    this.nodeResize = app.settings.nodeResize || [];

    this.getObserverConfig();
  }

  /**
   * Set up observers for all eligible nodes based on target selectors.
   * @return {undefined}
   */
  getObserverConfig() {
    this.nodeResize.forEach(config => {
      config.targetSelectors.forEach(targetSelector => {
        const targets = this.getTargets(targetSelector);

        targets.forEach(target => {
          if (this.isObserverEligible(target, config)) this.setupObserver(target, config);
        });
      });
    });
  }

  /**
   * Setup resize observer.
   * @param {DOM Node}   target
   * @param {Object}     config
   * @return {undefined}
   */
  setupObserver(target, config) {
    const condition = this.getCondition(config);
    const callback = this.getCallback(config);
    const continuous = this.getContinuous(config);

    Logger.log(`Observing node resize for ${target}.`);
    if (target && condition) ObserverResize.observe({ target, condition, callback, continuous });
  }

  /**
   * Is this config observer eligible?
   * @param {DOM Node}   target
   * @param {Object}     config
   * @return {Boolean}
   */
  isObserverEligible(target, config) {
    return target && config.observe && config.observeLimit && this.isBreakpointEligible(config);
  }

  /**
   * Should the observer be setup for this breakpoint?
   * @param {Object}     config
   * @return {Boolean}
   */
  isBreakpointEligible(config) {
    const mediaQueryMin = window.matchMedia(`(min-width: ${config.breakpointMin}px)`);
    const mediaQueryMax = window.matchMedia(`(max-width: ${config.breakpointMax}px)`);

    const breakpointMinEligible = config.breakpointMin ? mediaQueryMin.matches : true;
    const breakpointMaxEligible = config.breakpointMax ? mediaQueryMax.matches : true;

    return breakpointMinEligible && breakpointMaxEligible;
  }

  /**
   * Get the target node.
   * @param {String}     targetSelector
   * @return {Array}
   */
  getTargets(targetSelector) {
    return document.querySelectorAll(targetSelector);
  }

  /**
   * Condition that determines when the resize observer callback runs.
   * @param {Object}     config
   * @return {Function}
   */
  getCondition(config) {
    return entry => {
      switch (config.observe) {
        case 'minHeight':
          return entry.contentRect.height > config.observeLimit && this.getObserveSelectorCondition(config);
        case 'maxHeight':
          return entry.contentRect.height < config.observeLimit && this.getObserveSelectorCondition(config);
        case 'minWidth':
          return entry.contentRect.width > config.observeLimit && this.getObserveSelectorCondition(config);
        case 'maxWidth':
          return entry.contentRect.width < config.observeLimit && this.getObserveSelectorCondition(config);
        default:
          Logger.log(`${config.observe} is not a valid node resize condition`);
          return false;
      }
    };
  }

  /**
   * Determines if we have selectors we want to check for on the page
   * If selectors exist in the config, the method checks if they exist on the page
   * If they don't, we return true because we don't want to affect the main condition
   *
   * @param {Object}     config
   * @return {boolean}
   */
  getObserveSelectorCondition(config) {
    if (config.observeSelectors) {
      return Boolean(this.app.dom.querySelectorAll(config.observeSelectors).length);
    } else {
      return true;
    }
  }

  /**
   * Callback that is run when the target is resized based on the observer condition.
   * @param {Object}     config
   * @return {Function}
   */
  getCallback(config) {
    return () => {
      if (config.resize) this.resizeNode(config);
      if (config.insert.includedSlots === false) return;
      this.insertSlots(config);
    };
  }

  /**
   * If the resize observer should continue monitoring after observing resize.
   * @param {Object}     config
   * @return {Boolean}
   */
  getContinuous(config) {
    return !!config.continuous;
  }

  /**
   * Node resize when resize is observed.
   * @param {Object}     config
   * @return {undefined}
   */
  resizeNode(config) {
    const offset = config.resize.match === 'width' ? 'offsetWidth' : 'offsetHeight';
    const resizeElement = document.querySelector(config.resize.resizeSelector);
    const matchElement = document.querySelector(config.resize.matchSelector);

    if (resizeElement && matchElement) return (resizeElement.style[config.resize.match] = `${matchElement[offset]}px`);
  }

  /**
   * Slot insertion when resize is observed.
   * @param {Object}     config
   * @return {undefined}
   */
  insertSlots(config) {
    const opts = {
      domSelector: config.insert.domSelector,
      includedSlots: config.insert.includedSlots,
      excludedSlots: config.insert.excludedSlots,
    };

    return this.app.insertSlots(opts);
  }
}
