import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'intersection-observer';
import './vendor/matches-polyfill';

export { default } from './instance';

// Putting this in an IIFE so it runs after webpack assigns ConcertAds to the global variable
(() => {
  if (typeof window !== 'undefined') {
    setTimeout(() => {
      window.dispatchEvent(new Event('concertAdsReady'));
    }, 0);
  }
})();
