import Constants from './constants';
import { devtools } from './utils';

var startTime;

/**
 * A single entry within the log.
 */
export class LogEntry {
  /**
   * Constructor
   * @type {Object} data
   */
  constructor(data = {}) {
    this.timestamp = Date.now();
    this.message = data.message || '';
    this.level = data.level || 'log';

    delete data.message;
    delete data.level;

    this.debugInfo = data;

    if (['log', 'warn', 'error'].indexOf(this.level) === -1) {
      this.level = 'log';
    }
  }

  /**
   * Sends the log entry to the Console.
   */
  sendToConsole() {
    if (typeof console !== 'undefined' && console[this.level] && !__TEST__) {
      var ms = this.timestamp - startTime;

      console[this.level](`ConcertAds: (${ms}ms) ${this.message}`);
    }
  }
}

/**
 * The collection of LogEntries.
 */
export class Logger {
  /**
   * Setup the LogBook with the needed data.
   * @type {Object}
   */
  constructor() {
    startTime = Date.now();

    this.logs = [];
    this.debuggerEnabled = Constants.DEBUG_ENABLED;
  }

  /**
   * Adds a log entry to the LogBook.
   * @type {Object}
   */
  log(message, data = {}) {
    data.message = message;

    let logEntry = new LogEntry(data);

    if (this.debuggerEnabled || /warn|error/.test(logEntry.level)) {
      logEntry.sendToConsole();
    }

    this.logs.push(logEntry);

    if (devtools) {
      devtools.emit('log', logEntry);
    }
  }

  /**
   * Log a warning message. Same as calling log(message, { level: 'warn' }).
   * @param {string} message
   * @param {object} data
   */
  warn(message, data = {}) {
    this.log(message, {
      ...data,
      level: 'warn',
    });
  }

  /**
   * Log an error message. Same as calling log(message, { level: 'error' }).
   * @param {string} message
   * @param {object} data
   */
  error(message, data = {}) {
    this.log(message, {
      ...data,
      level: 'error',
    });
  }

  /**
   * Enables debugging from happening.
   */
  enableDebugging() {
    this.debuggerEnabled = true;
  }

  /**
   * Sends all messages to the console.
   */
  sendToConsole() {
    this.enableDebugging();

    this.logs.forEach(logEntry => {
      logEntry.sendToConsole();
    });
  }

  /**
   * Get all entries with a certain level of log in it.
   * @type {String}
   */
  getLogEntriesByLevel(level = 'log') {
    return this.logs.filter(logEntry => logEntry.level === level);
  }

  /**
   * Get log entries that have a specific data key.
   *
   * @param {string} key The data key to search for.
   */
  getLogEntriesWithData(key) {
    // eslint-disable-next-line no-prototype-builtins
    return this.logs.filter(logEntry => logEntry.debugInfo.hasOwnProperty(key));
  }
}

export default new Logger();
