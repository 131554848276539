export const DEFAULT_REFRESH_RATE_IN_SECONDS = 15;

/**
 * Get the refresh rate for ad slots, in milliseconds.
 *
 * @param {{ settings?: any, slots?: any }} options  Settings & slots objects
 * @param {string?} url URL to test (optional)
 * @returns {number} Refresh rate, in milliseconds.
 */
export function getRefreshRate({ settings, slot }, url) {
  /**
   * Settings-based refresh rate priorities:
   * 1. Slot-level
   * 2. Settings-level
   * 3. Default
   */
  const refreshRates = [slot && slot.refreshRate, settings && settings.refreshRate, DEFAULT_REFRESH_RATE_IN_SECONDS];
  const refreshRateFromSettings = refreshRates.find(rate => rate !== undefined && rate !== false);

  // If there is a URL param defined, it always takes priority.
  const refreshRateFromUrl = getRefreshRateFromUrl(url);
  const rateInSeconds = refreshRateFromUrl !== null ? refreshRateFromUrl : refreshRateFromSettings;

  return rateInSeconds * 1000;
}

/**
 * Refresh rate can be overridden by passing ?concert_ads_refresh_rate=<int> in the query.
 *
 * @param {string?} url  URL to test (optional)
 * @returns {int|null}
 */
function getRefreshRateFromUrl(url) {
  const rate = (url || window.location.toString()).match(/concert_ads_refresh_rate=(\d+)/);

  if (!rate) return null;

  return parseInt(rate.pop());
}

/**
 * Determine whether refresh is disabled. If the refresh rate is set as 0, refresh
 * will be disabled.
 * Refresh rate is also disabled for users with a subscription.
 *
 * @param {{ settings: {} }}
 * @param {string?} url
 */
export function isRefreshDisabled({ settings }, url = window.location.href) {
  return getRefreshRate({ settings }, url) === 0 || /concert_ads_disable_refresh/.test(url) || settings.hasSubscription;
}
