/**
 * Given a value, find the nearest "bucket" the value matches based on a precision.
 *
 * @param {{
 *  value: number,
 *  precision: number,
 *  upperBounds?: number,
 *  lowerBounds?: number
 * }}
 *
 */
export function getClosestBucketForValue({ value, precision, lowerBounds = 0, upperBounds = Infinity }) {
  if (value < lowerBounds) return lowerBounds;

  for (const bucket of generateBuckets(precision, lowerBounds, upperBounds)) {
    if (value === bucket) {
      return bucket;
    }

    // Find the lower and upper limit of this bucket (half of the precision)
    // and see if the value sits in that range.
    const lowerLimit = bucket - precision / 2;
    const upperLimit = bucket + precision / 2;

    if (lowerLimit <= value && upperLimit > value) {
      return bucket;
    }
  }

  // If we've reached this point and _still_ haven't found a bucket, return
  // the upper bounds
  return upperBounds;
}

/**
 * Generator function which returns an iteratable of buckets based on a precision 🏀
 *
 * For example, you could request a series of numbers in increments of 1:
 *
 * generateBuckets(1); // [0, 1, 2, 3, ...]
 *
 * NOTE: Be sure to set an upperBounds, or handle your iterator accordingly, otherwise
 * you'll get an infinite loop.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/function*
 *
 * @param {number}  precision   Precision of the buckets
 * @param {number?} lowerBounds The lower bounds
 * @param {number?} upperBounds The upper bounds
 */
export function* generateBuckets(precision, lowerBounds = 0, upperBounds = Infinity) {
  // We need to convert our floating-point precision to be an integer.
  // Let's start by getting the multiplier necessary to do so.
  const precisionMultiplier = getPrecisionMultiplier(precision);

  // Multiply our lowerBounds, upperBounds and precision by the multiplier
  // to get the integer values.
  const lowerBoundsInt = lowerBounds * precisionMultiplier;
  const upperBoundsInt = upperBounds * precisionMultiplier;
  const precisionInt = precision * precisionMultiplier;

  let bucket = lowerBoundsInt;

  while (bucket <= upperBoundsInt) {
    // Convert the yielded value back to a float for consumption
    yield bucket / precisionMultiplier;

    bucket += precisionInt;
  }

  return;
}

/**
 * Return the multiplier (powers of 10) necessary to move the decimal place
 * so that a precision can be an integer instead of a float.
 *
 * @param {number} precision Float of the precision
 */
export function getPrecisionMultiplier(precision) {
  // Convert our precision to a string so we can check for decimals
  const precisionString = String(precision);

  // By default, our multipler is one. This works great for whole numbers.
  let precisionMultiplier = 1;

  // If our precision has a decimal place (is a float), we need to convert it to an integer
  // This is because floating point math is tricky and leads to unpredictable results
  if (precisionString.indexOf('.') !== -1) {
    const decimalPlaces = precisionString.split('.')[1].length;
    precisionMultiplier = Math.pow(10, decimalPlaces);
  }

  return precisionMultiplier;
}
