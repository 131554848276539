import Logger from '../logger';
import Slot from '../slot';
import ScriptLoader from '../script_loader';

export default class BuyButtonSlot extends Slot {
  constructor(app, data, neighbor) {
    super(app, data, neighbor);
  }

  /**
   * Show Buy Button slot content
   * @return {undefined}
   */
  async render() {
    if (!this.canBeDisplayed() || !this.isProductEligible()) return;

    Logger.log(`Rendering ${this.name}`);

    super.render();
    this.setupAdSense();
    await this.loadAdSenseScript();
    this.updateProductElement();
    this.callAdSenseWithProduct();
    this.destroy();
  }

  /**
   * Do required configs exist?
   * @return {Boolean}
   */
  canBeDisplayed() {
    return (
      super.canBeDisplayed() &&
      this.app.settings.googleBuyButton.url &&
      this.app.settings.googleBuyButton.pubId &&
      this.app.settings.googleBuyButton.settingsId
    );
  }

  /**
   * Do required product element attributes exsit?
   * @return {Boolean}
   */
  isProductEligible() {
    return this.neighbor.hasAttribute('data-product-query') && this.neighbor.hasAttribute('data-gpcid');
  }

  /**
   * Setup Ad Sense
   * @return {undefined}
   */
  setupAdSense() {
    if (window._googCsa !== undefined) return;

    (function(g, o) {
      (g[o] =
        g[o] ||
        function() {
          (g[o].q = g[o].q || []).push(arguments);
        }),
        (g[o].t = 1 * new Date());
    })(window, '_googCsa');
  }

  /**
   * Load Google Ad Sense script
   * @return {Promise}
   */
  loadAdSenseScript() {
    const dependency = {
      url: this.app.settings.googleBuyButton.url,
    };

    return new ScriptLoader()
      .load(dependency)
      .then(url => {
        Logger.log(`${this.name} script ${url} has loaded successfully`);
      })
      .catch(error => {
        Logger.log(`Error loading ${this.name} script: ${error}`);
        Logger.log(`${this.name} has been collapsed.`);
        this.wrapper.collapseSlotWrapper();
        this.collapsed();
      });
  }

  /**
   * Update the product element if required
   * @return {undefined}
   */
  updateProductElement() {
    if (this.productElementStyle) this.neighbor.style.margin = this.productElementStyle;
    if (!this.neighbor.hasAttribute('id')) this.neighbor.setAttribute('id', this.id);
  }

  /**
   * Call Ad Sense with product data
   * @return {undefined}
   */
  callAdSenseWithProduct() {
    window._googCsa(
      'plas',
      {
        pubId: this.app.settings.googleBuyButton.pubId,
        query: this.neighbor.dataset.productQuery,
        gpcId: this.neighbor.dataset.gpcid,
        settingsId: this.app.settings.googleBuyButton.settingsId,
      },
      {
        container: this.neighbor.getAttribute('id'),
        width: 300,
        number: 1,
      }
    );

    this.rendered();
    this.markAsDisplayed();
  }

  static isSlotTypeFor(config) {
    return config.type === 'buy_button';
  }
}
