import { ResizeObserver as Polyfill } from '@juggle/resize-observer';
import Logger from './logger';

// Uses native or polyfill, depending on browser support.
const ResizeObserver = window.ResizeObserver || Polyfill;

/**
 * Watch a node for resize
 * @param  {DOM Node} target
 * @param  {Function} condition
 * @param  {Function} callback
 * @param  {Boolean} continuous
 * @return {undefined}
 */
export default class ObserverResize {
  static observe({ target, condition, callback, continuous = false }) {
    if (!document.body.contains(target)) {
      Logger.log(`${target} was not observed because node does not exist.`);
      return;
    }

    boundsObserver(condition, callback, continuous).observe(target);
  }
}

/**
 * Gets the resize observer object since this is a static method
 * @param  {Function} condition
 * @param  {Function} callback
 * @param  {Boolean} continuous
 * @return {ResizeObserver}
 */
function boundsObserver(condition, callback, continuous) {
  return new ResizeObserver((entries, observer) => {
    Logger.log(`Resize observer is being called`, { entries: entries });

    for (let entry of entries) {
      // Browsers with native support may be behind on the latest specification. For the condition,
      // use entry.contentRect since we are switching between native and polyfilled versions.
      if (condition(entry)) {
        try {
          Logger.log(`Resize observer callback has been called`);
          callback();
        } catch (error) {
          Logger.log(`There was a problem with the resize observer callback: ${error}`);
        }

        if (!continuous) observer.unobserve(entry.target);
      }
    }
  });
}
