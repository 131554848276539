import Logger from './logger';
import ObserverResize from './observer_resize';
import { getViewportWidth } from './utils';

export default class ViewportResize {
  /**
   * Destroy existing and insert new slots based on viewport resize.
   */
  constructor(app) {
    this.app = app;
    this.viewportResize = app.settings.viewportResize;
    this.viewportWidth = getViewportWidth();

    if (this.viewportResize) this.setupObserver();
  }

  /**
   * Setup resize observer.
   * @return {undefined}
   */
  setupObserver() {
    const condition = this.getCondition();
    const callback = this.getCallback();

    const opts = {
      target: document.body,
      condition,
      callback,
      continuous: true,
    };

    Logger.log(`Observing viewport resize.`);
    ObserverResize.observe(opts);
  }

  /**
   * Condition that determines when the resize observer callback runs.
   * @return {Function}
   */
  getCondition() {
    return entry => this.isSmallResize(entry) || this.isLargeResize(entry);
  }

  /**
   * Callback that is run when the target is resized based on the observer condition.
   * @return {Function}
   */
  getCallback() {
    return () => {
      this.viewportWidth = getViewportWidth();
      this.app.uninstall();
      this.app.insertSlots();
    };
  }

  /**
   * Is this a resize to a small viewport?
   * @param {Object}     entry
   * @return {Boolean}
   */
  isSmallResize(entry) {
    // Returns true if the resize is to a small viewport width based on any of the breakpoint values set in the configs and
    // the last measured viewport width is a large breakpoint value, meaning this is a resize from large to small.
    if (Array.isArray(this.viewportResize)) {
      return this.viewportResize.some(viewportSize => {
        return entry.contentRect.width < viewportSize && this.viewportWidth > viewportSize;
      });
    } else {
      return entry.contentRect.width < this.viewportResize && this.viewportWidth > this.viewportResize;
    }
  }

  /**
   * Is this a resize to a large viewport?
   * @param {Object}     entry
   * @return {Boolean}
   */
  isLargeResize(entry) {
    // Returns true if the resize is to a large viewport width based on any of the breakpoint values set in the configs and
    // the last measured viewport width is a small breakpoint value, meaning this is a resize from small to large.
    if (Array.isArray(this.viewportResize)) {
      return this.viewportResize.some(viewportSize => {
        return entry.contentRect.width > viewportSize && this.viewportWidth < viewportSize;
      });
    } else {
      return entry.contentRect.width > this.viewportResize && this.viewportWidth < this.viewportResize;
    }
  }
}
