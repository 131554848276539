/* eslint-disable camelcase */

import Logger from '../logger';
import Slot from '../slot';

export default class OpenWebSlot extends Slot {
  constructor(app, data) {
    super(app, data);
  }

  /**
   * Show an ad in this slot
   * @return {undefined}
   */
  render() {
    if (!super.render()) return;
    Logger.log(`Loading ${this.name}`);
    this.loadOpenWeb();
  }

  /**
   * Determine whether this slot can be displayed on the page
   * @return {boolean}
   */
  canBeDisplayed() {
    return super.canBeDisplayed() && this.data.scriptUrl != undefined && this.data.trackingPxUrl != undefined;
  }

  /**
   * Load OpenWeb scripts and Dom Hooks
   * @return {undefined}
   */
  loadOpenWeb() {
    this.openWebElement = document.createElement('div');

    const attributes = {
      'data-openweb-ad': 'true',
      'data-row': '1',
      'data-column': '1',
    };

    Object.keys(attributes).forEach(attribute => this.openWebElement.setAttribute(attribute, attributes[attribute]));
    this.openWebElement.classList.add('OPENWEB');
    this.element.appendChild(this.openWebElement);

    const openWebScript = document.createElement('script');
    openWebScript.type = 'text/javascript';
    openWebScript.src = this.data.scriptUrl;
    // Unclear if the next line is required. The source file in the GTM includes it, but it doesn't seem to have an effect.
    openWebScript.setAttribute('async', 'null');
    openWebScript.setAttribute('data-spotim-autorun', 'false');
    openWebScript.setAttribute('data-spotim-module', 'spotim-launcher');
    this.element.appendChild(openWebScript);

    const trackingPxImg = document.createElement('img');
    trackingPxImg.setAttribute('style', 'display:none;');
    trackingPxImg.setAttribute('width', '1');
    trackingPxImg.setAttribute('height', '1');
    trackingPxImg.src = this.data.trackingPxUrl;
    this.element.appendChild(trackingPxImg);
  }

  static isSlotTypeFor(config) {
    return config.type === 'open-web';
  }
}
