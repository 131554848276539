import { variablesMixin } from './mixins/variables';
import { initMixin } from './mixins/init';
import { installMixin } from './mixins/install';
import { registerMixin } from './mixins/register';
import { remoteConfigMixin } from './mixins/remote_config';
import { identityMixin } from './mixins/identity';
import { EventTypes } from '../lib/events';

function ConcertAds(config) {
  this.VERSION = VERSION;
  this._init(config);
}

ConcertAds.events = EventTypes;

initMixin(ConcertAds);
variablesMixin(ConcertAds);
installMixin(ConcertAds);
registerMixin(ConcertAds);
identityMixin(ConcertAds);
remoteConfigMixin(ConcertAds);

export default ConcertAds;
