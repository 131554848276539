import Events, { EventTypes } from '../lib/events';

/**
 * Base class to be extended by plugins
 * Each plugin should only impliment the following functions:
 *   prepare - this plugin is loaded and ready
 *   onInstall - Concrt Ads has been setup
 *   onSettingsLoaded - the remote settings are now readable
 */
export default class PluginBase {
  constructor({ app }) {
    this.app = app;

    Events.on(EventTypes.settingsAvailable, () => {
      this.onSettingsLoaded();
    });
    Events.on(EventTypes.installing, () => {
      this.onInstall();
    });
    Events.on(EventTypes.bidManagerCreated, () => {
      this.onBidManagerCreated();
    });
    Events.on(EventTypes.slotPublishedToAdServer, (eventName, data) => {
      this.onSlotPublishedToAdServer(eventName, data);
    });
    Events.on(EventTypes.slotObserved, (eventName, data) => {
      this.onSlotObserved(eventName, data);
    });
    Events.on(EventTypes.adRendered, (eventName, data) => {
      this.onAdRendered(eventName, data);
    });

    this.prepare();
  }

  prepare() {}
  onInstall() {}
  onSettingsLoaded() {}
  onBidManagerCreated() {}
  onSlotPublishedToAdServer() {}
  onSlotObserved() {}
  onAdRendered() {}
}
