import Logger from '../lib/logger';

export default class Slots {
  /**
   * Create a Slots collection.
   *
   * @param {ConcertAds} app
   */
  constructor({ app }) {
    this.app = app;
    this.slots = new Map();
  }

  /**
   * Get all slots.
   *
   * @returns {Array} Slots
   */
  all() {
    return Array.from(this.slots.values());
  }

  /**
   * Clear out all slots - for testing purposes.
   */
  clear() {
    this.slots.clear();
  }

  /**
   * Add a slot to the collection.
   * @param  {Slot} slot
   * @return {undefined}
   */
  push(slot) {
    if (slot.name) {
      this.slots.set(slot.name, slot);
    } else {
      Logger.log('Unable to add a slot to the slots object because it has no name.');
    }
  }

  /**
   * Get a slot by name. Used only by tests.
   * @param  {String} [name=''] name
   * @return {Slot|Boolean} The slot, or false if it doesn't exist.
   */
  get(name = '') {
    if (this.slots.has(name)) {
      return this.slots.get(name);
    } else {
      return false;
    }
  }

  get length() {
    return this.slots.size;
  }

  /**
   * Get a slot by the Google slot object returned from callbacks.
   * @param  {slot} googleSlot   googletag's slot object - NOT ours
   * @return {Slot}              Our instance of Slot (contains the above slot)
   */
  getByGoogleSlot(googleSlot) {
    return this.all().find(slot => slot.slot === googleSlot);
  }

  /**
   * Filter slots by a function. Used only by tests.
   * @param  {Function} fn Closure
   * @return {array}       Slots
   */
  filter(fn) {
    return this.all().filter(fn);
  }

  /**
   * Iterate over each slot in the collection.
   * @param  {Function} cb             Callback
   * @param  {context}   [context=this]
   * @return {undefined}
   */
  forEach(cb, context = this) {
    this.slots.forEach(cb, context);
  }
}
