import { fetchJson } from '../lib/fetch_json';
import uuidv1 from 'uuid/v1';
import Logger from '../lib/logger';

export default {
  loadContextualSegments(options = {}) {
    const MIN_CONFIDENCE_LEVEL = 0.04;

    return new Promise(resolve => {
      const { endpoint, targetingKey, model, version } = options;

      if (!endpoint || !targetingKey) {
        Logger.error(`An endpoint, network, and targetingKey must be passed to the loadContextualSegments classifier`);
        return resolve(false);
      }

      // Pull entry metadata from dfpVariables, if available
      const entryId = this.settings.dfpVariables.entry_id ? this.settings.dfpVariables.entry_id[0] : '';
      const entryTitle = this.settings.dfpVariables.entry_title ? this.settings.dfpVariables.entry_title[0] : '';

      const pageUrl = getEntryUrl();
      const referrer = document.referrer;

      const uid = getOrCreateUid();
      const payload = {
        uid,
        cid: entryId,
        title: entryTitle,
        url: pageUrl,
        ref: referrer,
        m: model,
        v: version,
      };

      fetchJson(endpoint, { method: 'POST', data: payload, contentType: 'application/json' })
        .then(result => {
          /**
           * API response is shaped like:
           * { interest_segment: [{ '28': '0.05' }, { '8': '0.04', '23': '0.04' }] }
           */
          const { data } = result;
          if (data && data.interest_segment && Array.isArray(data.interest_segment)) {
            let segments = [];

            data.interest_segment.forEach(segmentPair => {
              const [[segment, confidence]] = Object.entries(segmentPair);

              if (parseFloat(confidence) >= MIN_CONFIDENCE_LEVEL) {
                segments.push(segment);
              }
            });

            resolve({
              [targetingKey]: segments,
            });
          } else {
            resolve(false);
          }
        })
        .catch(e => {
          Logger.error(`loadContextualSegments failed to fetch from ${endpoint}`, {
            error: e.message,
          });
          resolve(false);
        });
    });
  },
};

export function getEntryUrl() {
  const canonicalElement = document.querySelector('link[rel="canonical"][href]');

  if (!canonicalElement) return '';

  return canonicalElement.href;
}

export function getOrCreateUid() {
  const UID_STORAGE_KEY = 'csg_uid';

  const uidFromLocalStorage = localStorage.getItem(UID_STORAGE_KEY);
  if (uidFromLocalStorage) return uidFromLocalStorage;

  const uid = uuidv1();
  localStorage.setItem(UID_STORAGE_KEY, uid);

  return uid;
}
